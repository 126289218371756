/* global
ioSocket
*/
import { Module, ActionContext } from 'vuex'
import { Meter, MeterStoreState } from './typedef'
import { RootState } from '../../typedef'
import _ from 'lodash'

const meterMap: { [uuid: string]: Meter } = {}

const state = {
  meterMap
}

const namespaced = true

export const MeterStore: Module<MeterStoreState, RootState> = {
  namespaced,
  state,
  mutations: {
    add (state, rawMeter: Meter) {
      state.meterMap[rawMeter.uuid] = rawMeter
    },
    addMany (state, metersAndSiteUuid) {
      _.each(metersAndSiteUuid.meters, m => {
        state.meterMap[m.uuid] =
        { ...m, ...{ siteUuid: metersAndSiteUuid.siteUuid } }
      })
    }
  },
  actions: {
    async addFavoriteSensor (
      action: ActionContext<MeterStoreState, RootState>,
      opts: {site: string; name: string}
    ): Promise<boolean> {
      const success = await ioSocket.get('/Sensors/addFavorite', {
        site: opts.site,
        name: opts.name
      }).catch(function (error) {
        console.error(error)
      }) as boolean[]

      return success[0]
    },
    async removeFavoriteSensor (
      action: ActionContext<MeterStoreState, RootState>,
      opts: {site: string; name: string}
    ): Promise<boolean> {
      const success = await ioSocket.get('/Sensors/removeFavorite', {
        site: opts.site,
        name: opts.name
      }).catch(function (error) {
        console.error(error)
      }) as boolean[]

      return success[0]
    },
    async read (
      action: ActionContext<MeterStoreState, RootState>,
      uuid: string
    ): Promise<Meter> {
      const meter = action.state.meterMap[uuid]

      if (meter) { return meter }

      const meterInstance = await ioSocket.get('/Sensors/get', { meters: uuid })
        .then(meter => {
          action.commit('add', (meter as Meter[])[0])
          return meter as Meter
        })
        .catch((error: Error) => {
          console.error('read meter', error)
          throw Error('could not read meter')
        })
      return meterInstance
    },
    async readMany (
      action: ActionContext<MeterStoreState, RootState>,
      uuids: string[]
    ): Promise<Array<Meter>> {
      const meters = await ioSocket.get('/Sensors/get', { meters: uuids })
        .then(result => {
          _.forEach(result as Meter[], m => {
            action.commit('add', m)
          })
          return result as Meter[]
        })
        .catch((error: Error) => {
          console.error('read meters', error)
          throw Error('could not read meters')
        })

      return meters
    }
  }
}
