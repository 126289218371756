








































































import Vue from 'vue'
import { State, Action } from 'vuex-class'

// import store from "../../store";
import { BaseState } from '../../store/modules/base/typedef'
import SiteSearch from '@/components/site-search/index.vue'
import { Tag } from '../../store/modules/tag_store/typedef'
import { TagListItem } from './typedef'

import { Component, Prop, Watch } from 'vue-property-decorator'

// import moment, { Moment } from "moment";
import * as _ from 'lodash'

/**
 * Component assumes value does not change after/when dialog is open.
 * value is only updated at dialog close, while dialog open current
 * selected tags is maintained in localValues and localSelected
 */
@Component({
  components: { SiteSearch }
})
export default class TagPicker extends Vue {
  @State('BaseStore') baseStore!: BaseState;
  @Action('getSiteTags', { namespace: 'TagStore' }) getSiteTags!: (
    site: string
  ) => Tag[];

  @Action('getTags', { namespace: 'TagStore' }) getTags!: (
    tags: string[]
  ) => Tag[];

  @Prop() large!: boolean;
  @Prop() btnClass!: string;

  // sent/recieved selected tags
  @Prop() value!: string[];

  selected: TagListItem[] = [];
  // map of selected tags, synced on confirmed change
  localSelected: { [site: string]: string[] } = {};

  loading = false;
  dialog = false;
  selectedSite = '';

  // constants
  headers: object[] = [
    {
      text: 'Tag',
      align: 'left',
      value: 'name'
    },
    { text: 'Enhet', value: 'unit' },
    { text: 'Kommentar', value: 'comment' },
    { text: 'Nåverdi', value: 'value' },
    { text: 'Redigerbar', value: 'writable' }
  ];

  maxTags = 5;
  tagList: TagListItem[] = [];

  currentSiteTags: Tag[] = [];

  close () {
    // revert to previous selected
    this.localSelected = {}
    this.dialog = false
  }

  /**
   * sync selected tags for v-model and close dialog
   */
  save () {
    if (this.localValue.length > this.maxTags) return

    this.$emit('input', this.localValue)

    this.dialog = false
  }

  async getCurrentSiteTags () {
    try {
      this.currentSiteTags = await this.getSiteTags(this.selectedSite)
    } catch (err) {
      console.error('getCurrentSiteTags', err.message)
      this.currentSiteTags = []
    }
  }

  async updateLocalSelectedFromValue () {
    const tags: Tag[] = await this.getTags(this.value)

    const tagMap = _.mapKeys(this.tagList, 'uuid')

    this.selected = _.map(tags, tag => {
      return tagMap[tag.uuid]
    })
  }

  get buttonClass () {
    return this.btnClass ? this.btnClass + ' text-none' : 'text-none'
  }

  get currentSiteSelected (): string[] {
    return _.map(this.selected, 'uuid')
  }

  get localValue (): string[] {
    return _.flatMap(this.localSelected)
  }

  getTagList () {
    const selectedTags = this.localSelected[this.selectedSite] || []

    this.tagList = this.currentSiteTags.map(
      (tag: Tag): TagListItem => {
        const icon: string = tag.writable ? 'check' : 'clear'

        const listTag: TagListItem = {
          ...tag,
          writableIcon: icon,
          selected: selectedTags.some((selected: string) => {
            return selected === tag.uuid
          }),
          value: 'N/A'
        }

        return listTag
      }
    )
  }

  @Watch('dialog')
  async onDialogChange (dialogOpen: boolean) {
    // do nothing if dialog closing
    if (!dialogOpen) return

    this.selectedSite = this.baseStore.currentSiteUuid

    // this.selectedSite = store.state.BaseStore.currentSiteUuid;

    console.log('dialog table update')

    await Promise.all([
      this.updateLocalSelectedFromValue(),
      this.getCurrentSiteTags()
    ])

    this.getTagList()
  }

  @Watch('selectedSite')
  async onSiteChange (selectedSite: string) {
    console.log('select change', selectedSite)

    await this.getCurrentSiteTags()

    this.getTagList()
  }

  @Watch('currentSiteSelected')
  onCurrentSiteSelectedChange (selected: string[]) {
    // used to trigger getters on change
    Vue.set(this.localSelected, this.selectedSite, selected)
  }
}
