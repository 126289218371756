




import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { TagData } from './TagDataQuery'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Plotly from 'plotly.js-dist'

import uuid from '../../lib/uuid'

interface PlotConfig {
  layout: object;
  data: object;
}

@Component
export default class Plot extends Vue {
  @Prop() plotConfig!: PlotConfig;
  @Prop() tags!: Array<string>;
  @Prop() data!: TagData;
  // @Prop() tagMap!: {[uuid:string]: object};

  id: string = uuid();
  emptyPlot = true;

  mounted () {
    console.log('plotly div loaded', this)
  }

  @Watch('plotConfig')
  onPlotConfigChanged (config: PlotConfig) {
    if (this.emptyPlot) {
      this.emptyPlot = false
      Plotly.newPlot(
        this.id,
        config.data,
        // config.layout, {
        {
          displaylogo: false,
          responsive: true
        })
    } else {
      console.log('react')
      Plotly.react(this.id, config.data, config.layout)
    }
  }
}
