import { Moment } from 'moment'

export interface PlotConfig {
    resolution: Resolution;
    style: string;
    derived: boolean;
    log: boolean;
    live: boolean;

    from: Moment;
    to: Moment;
}

export interface ApiTagDataMeter {
    uuid: string;
    values: Array<ApiTagDataPoint>;
}

export interface ApiTagDataPoint {
    value: number;
    timestamp: string;
}

export type TagData = { [uuid: string]: Array<ApiTagDataPoint> }

export interface LineStyle {
    shape?: string;
}

export interface Trace {
    x: Array<string>; // dates
    y: Array<number>;
    name: string;
    uuid: string; // ???
    visible?: boolean;
    line?: LineStyle;
    type: string;
}

export type Resolution =
    'auto' | 'seconds' | 'minutes' | 'hours' | 'days' | 'months' | 'years';

export const EnergyTypes = ['Elektrisk', 'Vann', 'Olje', 'Diesel']
