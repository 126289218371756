/* global
ioSocket
*/
import moment, { Moment } from 'moment'
import _ from 'lodash'
import { Resolution } from '../typedef'

export interface ApiTagDataMeter {
  uuid: string;
  values: Array<ApiTagDataPoint>;
}
export interface ApiTagDataPoint {
  value: number;
  timestamp: string;
}

export type TagData = { [uuid: string]: Array<ApiTagDataPoint> }

export default class TagDataQuery {
  meters: string[];
  from: Moment;
  to: Moment;
  resolution: Resolution;
  data: TagData | null;

  constructor (
    meters: Array<string>,
    from: string | Moment,
    to: string | Moment,
    resolution: Resolution
  ) {
    this.meters = meters
    this.from = moment(from)
    this.to = moment(to)
    this.resolution = resolution

    this.data = null
  }

  async get (force: boolean) {
    if (!this.data || force) {
      try {
        const rawData = await ioSocket
          .get('/Tag/read', {
            tags: _.filter(this.meters, Boolean),
            resolution: this.resolution,
            from: this.from.toISOString(),
            to: this.to.toISOString()
          }).catch(console.warn)
        return this._parseRawData(rawData as ApiTagDataMeter[])
      } catch (error) {
        console.error('MeterDataQuery.get', error)
        throw error
      }
    } else { return Promise.resolve(this.data) }
  }

  _parseRawData (rawData: Array<ApiTagDataMeter>) {
    if (!this.data) { this.data = {} }

    _.each(rawData, (meter: ApiTagDataMeter) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.data![meter.uuid] = meter.values
    })

    return this.data
  }
}
