import moment from 'moment-timezone'
import { ApiEvent } from '../../../pages/ecalendar/typedef'
import BasicEvent from './basic_event'
import RepeatEvent from './repeat_event'

import { CalendarEvent } from './typedef'
export { CalendarEvent, VCalendarEvent } from './typedef'

export function ApiEventToCalendarEvent (
  event: ApiEvent,
  tz: string
): CalendarEvent {
  if (event.interval_length && event.interval_unit) {
    return new RepeatEvent(
      event.title,
      event.comment || '',
      moment(event.from),
      moment(event.to),
      event.exception,
      event.inactive,
      event.uuid,
      event.resource,
      event.group,
      tz,
      event.interval_length,
      event.interval_unit,
      event.expiredAfter ? moment(event.expiredAfter) : null,
      event.updatedAt ? moment(event.updatedAt) : undefined,
      event.createdAt ? moment(event.createdAt) : undefined,
      event.createdBy
    )
  } else {
    return new BasicEvent(
      event.title,
      event.comment || '',
      moment(event.from),
      moment(event.to),
      event.exception,
      event.inactive,
      event.uuid,
      event.resource,
      event.group,
      tz,
      event.updatedAt ? moment(event.updatedAt) : undefined,
      event.createdAt ? moment(event.createdAt) : undefined,
      event.createdBy
    )
  }
}
