




import _, { isNumber } from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import BigBtn from '@/components/buttons/big-btn.vue'
import { GraphStyle, Resolution } from '@/store/modules/eos_plot_store/typedef'
import { Trace } from './typedef'
import { ApiEosDataPoint, EosData } from './eosDataQuery'
import { mapState } from 'vuex'
import { Meter, Site } from '@/store/modules/site_store/typedef'
import { DateTime } from 'luxon'
import { MapPoints } from './map-points'

@Component({
  components: { BigBtn },
  computed: {
    ...mapState('MeterStore', { meterMap: 'meterMap' }),
    ...mapState('SiteStore', { siteMap: 'siteMap' })
  }
})
export default class PlotFormatter extends Vue {
  @Prop({ default: false }) cumulative!: boolean;
  @Prop({ default: false }) prArea!: boolean;
  @Prop({ default: Resolution.days }) resolution!: Resolution;

  meterMap!: { [uuid: string]: Meter };
  siteMap!: { [uuid: string]: Site };
  dateTimeFormat = 'yyyy-MM-dd HH:mm'

  mounted () {
    console.log('mounted PlotFormatter')
  }

  // Consumption
  formattedConsumption (
    eosData: EosData,
    graphStyle: GraphStyle,
    traceVisibility: { [uuid: string]: string } = {}
  ): Array<Trace> {
    return _.map(eosData, (dataPoints, uuid) => {
      const x = _.map(dataPoints, 'ts')
      const meterArea = this.getMeterArea(uuid)

      return {
        x: x,
        y: this.cumulative
          ? this.cumulativeArray(dataPoints, meterArea)
          : (meterArea > 1 ? _.map(dataPoints, (p) => {
            return p.value / meterArea
          })
            : _.map(dataPoints, 'value')),
        name: this.getMeterName(uuid),
        uuid,
        visible: traceVisibility[uuid] || 'true',
        line: {
          shape: 'spline',
          simplify: false
        },
        type: graphStyle
      }
    })
  }

  // Duration
  formattedDuration (
    eosData: EosData,
    graphStyle: GraphStyle,
    traceVisibility: { [uuid: string]: string } = {}
  ): Array<Trace> {
    const formatted = this.formattedConsumption(
      eosData,
      graphStyle,
      traceVisibility
    )
    _.forEach(formatted, trace => {
      trace.y.sort((a, b) => {
        return a - b
      })
      _.reverse(trace.y)
      trace.x = _.drop(_.times(trace.y.length + 1, String))
    })
    return formatted
  }

  // Compare
  formattedCompare (
    eosData: EosData,
    selectedYears: string[],
    graphStyle: GraphStyle,
    updateAvailableYears: ((years: string[]) => {}) | undefined = undefined,
    traceVisibility: { [uuid: string]: string } = {}
  ): Array<Trace> {
    let grouped: EosData = {}
    let meterUuid = ''
    let meterName = ''

    const form = _.map(eosData, (dataPoints, uuid) => {
      grouped = _.groupBy(dataPoints, function (point) {
        return DateTime.fromISO(point.ts).year
      })
      meterUuid = uuid
      meterName = this.getMeterName(meterUuid)
      return grouped
    })

    const years = _.keysIn(form[0])
    if (updateAvailableYears) updateAvailableYears(years)

    return _.compact(_.flatMap(form, data => {
      return _.map(data, (dataPoints, year) => {
        if (!selectedYears.includes(year)) return null
        const meterArea = this.getMeterArea(meterUuid)
        const x = this.mapPointsToResolution(dataPoints)
        return {
          x: x,
          y: this.cumulative
            ? this.cumulativeArray(dataPoints, meterArea)
            : _.map(dataPoints, (p) => {
              return _.round(p.value / meterArea, 2)
            }),
          name: '<span style="font-weight: bold;">' +
                year + '</span> ' + meterName,
          year: year,
          // Should trace visibility be forgotten when changing meter?
          uuid: meterName + year,
          visible: traceVisibility[meterName + year] || 'true',
          line: {
            shape: 'spline',
            simplify: false
          },
          type: graphStyle
        }
      })
    }))
  }

  mapPointsToResolution (points: ApiEosDataPoint[]): string[] {
    switch (this.resolution) {
      case Resolution.days: return MapPoints.mapPointsDays(points)
      case Resolution.weeks: return MapPoints.mapPointsWeek(points)
      case Resolution.months: return MapPoints.mapPointsMonth(points)
      default: return MapPoints.mapPointsWeek(points)
    }
  }
  // ET-curves

  // Helper functions
  cumulativeArray (points: ApiEosDataPoint[], meterArea = 1) {
    let sum = 0
    return _.map(points, (point) => {
      if (isNumber(point.value)) {
        sum += _.round(point.value / meterArea, 2)
      }
      return sum
    })
  }

  getMeterName (
    meterUuid: string,
    includeSite = true): string {
    const meter = this.meterMap[meterUuid]
    if (!meter) return ''

    const site = this.siteMap[meter.siteUuid]
    const name =
      meter.displayName +
      ' [' +
      meter.unit +
      (this.prArea ? '/m\u00B2' : '') +
      ']' + (includeSite
        ? ' (' +
      site.displayName +
      ')' : '')

    return name
  }

  getMeterArea (
    meterUuid: string): number {
    return this.prArea
      ? this.meterMap[meterUuid].area ||
          this.siteMap[this.meterMap[meterUuid].siteUuid].area ||
          1 // neither meter nor site has value for area
      : 1 // prArea not selected in active settings
  }
}
