/* global
ioSocket
*/
import { Module, ActionContext } from 'vuex'
import { RootState } from '../../typedef'
import {
  ElhubMeter,
  ElhubRelation,
  ElhubRelationEdit,
  // ElhubRelation,
  // ElhubRelationEdit,
  ElhubStoreState
} from './typedef'

const namespaced = true

const ElhubStore: Module<ElhubStoreState, RootState> = {
  namespaced,
  state: {
    elhubMeters: []
  },
  mutations: {
    addElhubMeters (state, elhubMeters: ElhubMeter[]) {
      state.elhubMeters.push(...elhubMeters)
    },
    editElhubMeter (state, elhubMeter: ElhubMeter|ElhubMeter[]) {
      elhubMeter = Array.isArray(elhubMeter) ? elhubMeter : [elhubMeter]

      elhubMeter.forEach((meter) => {
        const internalElhubMeterIndex = state.elhubMeters.findIndex(
          iElhubMeter => iElhubMeter.uuid === meter.uuid
        )
        state.elhubMeters.splice(internalElhubMeterIndex, 1, meter)
      })
    },
    removeElhubMeter (state, elhubMeter: ElhubMeter) {
      const internalElhubMeterIndex = state.elhubMeters.findIndex(
        iElhubMeter => iElhubMeter.uuid === elhubMeter.uuid
      )
      state.elhubMeters.splice(internalElhubMeterIndex, 1)
    }
  },
  actions: {
    async fetchElhubMeters (
      action: ActionContext<ElhubStoreState, RootState>
    ): Promise<ElhubMeter[]> {
      // const iotDevices = await ioSocket.get('/adminSettings/getDevices')
      if (!action.state.elhubMeters.length) {
        let page = 0
        let done = false
        const PAGE_SIZE = 100
        const relations: ElhubMeter[] = []
        while (!done) {
          const relationPage = await ioSocket.get(
            '/elhub/getAll',
            { page }
          ) as ElhubMeter[]
          relations.push(...relationPage)
          // done when relations length is less than page size
          done = relationPage.length < PAGE_SIZE
          page++
        }
        action.commit('addElhubMeters', relations)
      }
      return action.state.elhubMeters
    },
    async createElhubMeter (
      action: ActionContext<ElhubStoreState, RootState>,
      newElhubMeters: ElhubMeter[]
    ): Promise<ElhubMeter[]> {
      const elhubRelations: ElhubRelation[] = newElhubMeters.map((elhubMeter) => {
        return {
          gsrn: elhubMeter.gsrn,
          consumptionMeterUuid: elhubMeter.consumptionMeterUuid || '',
          productionMeterUuid: elhubMeter.productionMeterUuid || ''
        }
      })

      let res: ElhubMeter[]
      try {
        res = await ioSocket.get(
          '/elhub/create',
          { elhubRelations: elhubRelations }
        ) as ElhubMeter[]
      } catch (error) {
        throw new Error(`Could not create elhubRelations: ${elhubRelations}
        Error: ${error}`)
      }
      action.commit('addElhubMeters', res)
      return res
    },
    async editElhubMeter (
      action: ActionContext<ElhubStoreState, RootState>,
      newElhubMeter: ElhubMeter
    ): Promise<ElhubMeter> {
      const meterRelations: ElhubRelationEdit[] = [newElhubMeter]
        .map((newElhubMeter) => {
          return {
            uuid: newElhubMeter.uuid,
            gsrn: newElhubMeter.gsrn,
            lastAttempt: newElhubMeter.lastAttempt,
            accessRequested: newElhubMeter.accessRequested,
            productionMeterUuid: newElhubMeter.productionMeterUuid,
            consumptionMeterUuid: newElhubMeter.consumptionMeterUuid
          }
        })

      let res: ElhubMeter
      try {
        res = await ioSocket.get(
          '/elhub/edit',
          { meterRelations: meterRelations }
        ) as ElhubMeter
      } catch (error) {
        throw new Error(`Could not edit ElhubMeter: ${newElhubMeter}
        Error: ${error}`)
      }
      action.commit('editElhubMeter', res)
      return res
    },
    async removeElhubMeter (
      action: ActionContext<ElhubStoreState, RootState>,
      elhubMeter: ElhubMeter
    ): Promise<ElhubMeter> {
      let res: ElhubMeter
      try {
        res = await ioSocket.get(
          '/elhub/remove',
          { uuids: [elhubMeter.uuid] }
        ) as ElhubMeter
      } catch (error) {
        throw new Error(`Could not remove ElhubMeter: ${elhubMeter}
        Error: ${error}`)
      }
      action.commit('removeElhubMeter', res)
      return res
    }
  }
}
export default ElhubStore
