/* global
globalColors
*/
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

// locales
import nb from 'vuetify/src/locale/no'

Vue.use(Vuetify)

const iconPack: 'md' | 'mdi' | 'mdiSVG' | 'fa' | 'fa4' = 'md'

const vuetifyOpts = {
  theme: {
    dark: false,
    themes: {
      light: {
        primary: globalColors.main_color,
        // @ts-ignore
        secondary: globalColors.main_color_tint_2,
        // @ts-ignore
        accent: globalColors.stnd_col_yellow,
        // @ts-ignore
        error: globalColors.stnd_col_red,
        info: globalColors.main_color_tint_0,
        success: globalColors.stnd_col_green,
        warning: globalColors.stnd_col_yellow
      }
    },
    options: {
      customProperties: true
    }
  },
  icons: {
    iconfont: iconPack
  },
  lang: {
    locales: { nb },
    current: 'nb'
  },
  options: {
    customProperties: true
  }
}

export default new Vuetify(vuetifyOpts)
