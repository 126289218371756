



















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class PlotBtn extends Vue {
  @Prop() buttonName!: string
  @Prop() icon!: string;
  @Prop({ default: 'primary' }) color!: string

  elevation='0'
}
