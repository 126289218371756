














































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { mapState, mapMutations } from 'vuex'
import store from '../store/'
import SiteSearch from '@/components/site-search/index.vue'
import { User } from '../store/modules/user/'
import { Site } from '../store/modules/site_store/typedef'
import { filterMenuItems, MenuItem } from '../lib/menu_items'

@Component({
  components: {
    SiteSearch
  },
  store,
  computed: {
    ...mapState('BaseStore', { siteUuid: 'currentSiteUuid' }),
    ...mapState('BaseStore', { theme: 'theme' }),
    ...mapState('UserStore', { user: 'user' }),
    ...mapState('SiteStore', { siteMap: 'siteMap' })

  },
  methods: mapMutations('BaseStore', { changeSite: 'changeSite' })
})
export default class ESideNav extends Vue {
  @Prop() value!: boolean;
  @Prop() screenUHD!: boolean;

  // asigned by vuex

  siteUuid!: string
  theme!: string;
  user!: User;
  siteMap!: {[uuid: string]: Site};

  selectedSite = '';
  siteSearchDialog = false;

  changeSite!: (selectedSite: string) => never

  get site (): Site {
    return this.siteMap[this.siteUuid]
  }

  get logoPath () {
    return `/images/logos/${this.theme}/wpadding.svg`
  }

  get menuItems (): MenuItem[] {
    if (!this.site || !this.user) return []

    return filterMenuItems(this.site, this.user)
  }

  @Watch('selectedSite')
  async onSiteChange (selectedSite: string) {
    // close dialog
    this.siteSearchDialog = false
    this.open = false
    // change currentSite

    this.changeSite(selectedSite)
  }

  get open () {
    return this.value
  }

  set open (localValue) {
    this.$emit('input', localValue)
  }
}
