/* global
ioSocket
*/
import { Module, ActionContext } from 'vuex'
// import { getters } from './getters';
// import { actions } from './actions';
// import { mutations } from './mutations';
import { Tag, TagStoreState, TagResponse } from './typedef'
import { RootState } from '../../typedef'

const tagMap: { [uuid: string]: Tag | Promise<Tag> } = {}
const siteTagMap: { [uuid: string]: Array<Tag> } = {}

const state: TagStoreState = {
  tagMap,
  siteTagMap
}

const namespaced = true

// read(string | Array<string>): Promise<Array<Meter>>

export const TagStore: Module<TagStoreState, RootState> = {
  namespaced,
  state,
  // getters,
  mutations: {
    addSiteTags (state, tags: Array<TagResponse>) {
      tags.forEach(siteTags => {
        state.siteTagMap[siteTags.siteUuid] = siteTags.tags

        siteTags.tags.forEach(tag => {
          // mutate tag to add siteUuid
          tag.siteUuid = siteTags.siteUuid
          // add to tagMap
          state.tagMap[tag.uuid] = tag
        })
      })
    },
    add (state, tags: Array<Tag>) {
      tags.forEach(tag => {
        // add to tagMap
        state.tagMap[tag.uuid] = tag
      })
    },
    addSiteInFlight (
      state,
      siteTags: { prom: Promise<Array<Tag>>; siteUuid: string }
    ) {
      state.siteTagMap[siteTags.siteUuid] = siteTags.prom
    },
    addInFlight (
      state,
      tag: { prom: Promise<Tag>; uuid: string }
    ) {
      state.tagMap[tag.uuid] = tag.prom
    }
  },
  actions: {
    /**
         * @bug no inflight check
         * @param action
         * @param siteUuid
         */
    async getSiteTags (
      action: ActionContext<TagStoreState, RootState>,
      siteUuid: string
    ): Promise<Array<Tag>> {
      // return tags if exist OR return promise if query in-flight
      const tags = action.state.siteTagMap[siteUuid]
      if (tags) { return tags }

      const prom: Promise<Array<Tag>> = ioSocket
        .get('/Tag/get', { siteUuid })
        .catch((error: Error) => {
          // no access, give empty result
          if (error.message === 'Forbidden') {
            console.warn('no access to tags for site')

            return []
          } else {
            console.error('could not get tags of site', error)
            return Promise.reject(Error('could not get tags'))
          }
        })
        .then((res) => {
          let tags = res as TagResponse[]
          // mock empty tag list on no result
          if (!tags.length) {
            tags = [
              {
                siteUuid,
                tags: []
              }
            ]
          }

          action.commit('addSiteTags', tags)

          return action.state.siteTagMap[siteUuid]
        })

      // commit promise for inlight
      action.commit('addSiteInFlight', { prom, siteUuid })

      return prom
    },
    /**
         * @bug no inflight check
         * @param action
         * @param tagUuid
         */
    async getTags (
      action: ActionContext<TagStoreState, RootState>,
      tagUuid: string[]
    ): Promise<Array<Tag>> {
      const tagPromises = tagUuid.map((uuid: string) => {
        const tag = action.state.tagMap[uuid]
        // tag or promise found
        if (tag) { return tag }

        const tagPromise: Promise<Tag> = ioSocket
          .get('/Tag/getByUuid', { uuid: [uuid] })
          .catch((error: Error) => {
            // no access, give empty result
            if (error.message === 'Forbidden') {
              console.warn('no access to tag', uuid)
              return Promise.reject(Error('no access to tag ' + uuid))
            } else {
              console.error('could not get tag', error)
              return Promise.reject(Error('could not get tags'))
            }
          })
          .then((res) => {
            const tags = res as Tag[]
            // mock empty tag list on no result
            if (!tags.length) { return Promise.reject(Error('no tag returned')) }

            action.commit('add', tags)

            return tags[0]
          })

        // commit promise for inlight
        action.commit('addInFlight', { tag: tagPromise, uuid })

        return tagPromise
      })
      return Promise.all(tagPromises)
    }
  }
}
