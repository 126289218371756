






































































































































































































































































































/* global
    ioSocket
*/
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import * as _ from 'lodash'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import LiquorTree from 'liquor-tree'

import {
  dispTextSiteMeterPicker,
  EosParentNode,
  LTNode,
  TreeNode
} from '../eos-plot/typedef'
import { Site, Meter } from '@/store/modules/site_store/typedef'

import SiteSearch from '@/components/site-search/index.vue'
import BigBtn from '@/components/buttons/big-btn.vue'
import EConfirmDialog from '@/components/e-confirm-dialog.vue'
import MeterSelectorMenu from
  '@/components/eos-meter-selector/meter-selector-menu.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import { SiteMeter } from '@/store/modules/eos_plot_store/typedef'
import { MeterSelectorHelper } from './meter-selector-helper'
import { User } from '@/store/modules/user'

enum MeterType {
  electrical = 0,
  water = 1,
  oil = 2,
  diesel = 3,
  Site = -1,
}

@Component({
  components: {
    SiteSearch,
    MeterSelectorMenu,
    LiquorTree,
    BigBtn,
    EConfirmDialog
  },
  methods: {
    ...mapMutations('MeterStore', { addManyMeters: 'addMany' }),
    ...mapActions('MeterStore', ['addFavoriteSensor', 'removeFavoriteSensor']),
    ...mapActions('EosPlotStore', ['removeNode'])
  },
  computed: {
    ...mapState('BaseStore', { siteUuid: 'currentSiteUuid' }),
    ...mapState('SiteStore', { siteMap: 'siteMap' }),
    ...mapState('UserStore', { user: 'user' })
  }
})
export default class EosMeterSelector extends Vue {
  @Prop({ default: false }) value!: boolean;
  @Prop({ default: [] }) localSelected!: SiteMeter[];
  @Prop({ required: true }) updateTreeData!: (items: TreeNode[]) => {};
  @Prop({ required: true }) addMeter!: (siteMeter: SiteMeter) => {};
  @Prop({ required: true }) removeMeter!: (meter: Meter) => {};
  @Prop({ required: true }) meterDataLoading!: boolean;
  @Prop({ required: true }) getSelectedEnergyTypes!: string[];
  @Prop({ required: true }) getTreeData!: TreeNode[];
  @Prop({ required: true }) getMeters!: Meter[];
  @Prop({ required: true }) getTreeView!: boolean;
  @Prop({ required: true }) hierarchy!: EosParentNode[];
  @Prop({ required: true }) getSelectedSiteUuid!: string;
  @Prop({ required: true }) editMode!: boolean;
  @Prop({ required: true }) updateTreeView!: (treeView: boolean) => {};
  @Prop({ required: true }) updateSelectedEnergyTypes!: (types: number[]) => {};
  @Prop({ default: 20 }) maxSelectedMeter!: number;
  @Prop({ default: true }) useFavorites!: boolean;

  siteUuid!: string;
  user!: User;

  siteMap!: { [uuid: string]: Site };
  dispText = dispTextSiteMeterPicker.nb;
  meterType = MeterType;
  loadingTree = false; // set in parent component
  search = '';
  treeModel = '';
  selectedEnergyTypes: string[] = [];
  favoriteMetersUuids: string[] = [];

  snackbar = false;
  confirmDialog = false
  snackbarText = '';
  mainSite = ''

  visitedSites: Site[] = [];
  hasLoaded = false;

  settingsDrawer = true;
  treeKey = false;

  // Mutations
  addManyMeters!: (metersAndSiteUuid: {
    meters: Meter[];
    siteUuid: string;
  }) => {};

  // Actions
  addFavoriteSensor!: (opts: {
    site: string;
    name: string;
  }) => Promise<boolean>;

  removeFavoriteSensor!: (opts: {
    site: string;
    name: string;
  }) => Promise<boolean>;

  removeNode!: (uuid: string) => Promise<object>

  treeOptions = {
    filter: {
      emptyText: this.dispText.noFilteredData,
      matcher: this.nodeMatcher
    },
    dnd: true,
    checkbox: false,
    /* deletion (node) {
      alert('te')
      console.log('nodenodenode', node)
      return node.checked()
    }, */
    autoCheckChildren: false
  };

  get fullscreen (): boolean {
    return this.$vuetify.breakpoint.mobile // this.$vuetify.breakpoint.smAndDown
  }

  async deleteNode (uuid: string) {
    // console.log('deleteNode')
    try {
      await this.removeNode(uuid)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async promptDeleteNode (node: TreeNode) {
    const title = 'Er du sikker?'
    const cardText = 'Du vil slette gruppen ' + node.text
    const confirm = this.$refs.confirm as EConfirmDialog
    if (await confirm.open(title, cardText)) {
      console.log('promptDeleteNode confirm', node.text)
      if (node.data.uuid) this.deleteNode(node.data.uuid)
    } else {
      console.log('promptDeleteNode cancel', node.text)
    }
  }

  updateEditMode (e: boolean) {
    // console.log('updateeditmode selector', e)
    this.$emit('update:editMode', e)
  }

  isNodeAMeter (node: TreeNode): boolean {
    return !!node.data.meter.unit
  }

  /**
   * Checks if meter-node is source of temperature data
   * todo
   */
  isTempMeter (node: TreeNode): boolean {
    const selectedMetersUuids = _.map(this.localSelected, 'meter.uuid')
    return node.data.meter.uuid === selectedMetersUuids[0]
  }

  setVisibleDraggable (node: LTNode): boolean|undefined {
    node.states.draggable = this.editMode && this.getTreeView
    return node.states.visible
  }

  moveNode (targetNode: LTNode, distinationNode: LTNode) {
    let parentNodeUuid = null
    // DestinationNode is the 'closest' node, not necessarily the new parent
    const parentIsNeighbour = targetNode.depth === distinationNode.depth

    if (parentIsNeighbour) {
      parentNodeUuid = distinationNode.parent
        ? distinationNode.parent.data.uuid
        : null
    } else {
      parentNodeUuid = distinationNode.data.uuid
    }

    ioSocket
      .get('/Hierarchy/move', {
        uuid: targetNode.data.uuid,
        parent: {
          uuid: _.isNil(parentNodeUuid) ? null : parentNodeUuid,
          siteUuid: _.isNil(parentNodeUuid) ? this.selectedSite : null
        }
      })
      .catch(console.warn)
  }

  selectNode (node: LTNode): void {
    const isSensor = node.data.meter.type !== undefined
    // console.log('selectNode:', node.text, isSensor, node.states.checked)

    if (isSensor) {
      node.states.checked = !node.states.checked
      node.states.selected = false
      this.checkMeter(node.states.checked, node.data.meter)
    }
  }

  saveNodeEdit (node: LTNode, newText: string, oldText: string): void {
    console.log('saveNodeEdit', node, 'new:', newText, 'old:', oldText)
    ioSocket
      .get('/Hierarchy/editNode', {
        uuid: node.data.meter.uuid,
        displayName: newText
      })
      .catch(console.warn)
  }

  checkMeter (checked: boolean, meter: Meter): void {
    // console.log('checkMeter', checked, meter.displayName, meter)
    this.snackbar = false
    if (checked) {
      this.addMeter({ siteUuid: this.selectedSite, meter: meter })
    } else {
      this.removeMeter(meter)
    }
  }

  /**
   * Query is the combination of search and selected energy types
   */
  get treeQuery (): string {
    // console.log('treeQuery')
    if (!this.hasLoaded) { return this.search }
    return this.getSelectedEnergyTypes.length === 0 && this.search === ''
      ? this.search
      : this.search + '&selected:' + this.getSelectedEnergyTypes
  }

  get treeData (): TreeNode[] {
    // console.log('get treeData', this.localSelected)
    const selectedOnSite = _.map(this.localSelected, (sm) => {
      if (sm.siteUuid === this.getSelectedSiteUuid) {
        return sm.meter.uuid
      }
    })
    return MeterSelectorHelper.filterSelected(
      _.cloneDeep(this.getTreeData),
      selectedOnSite
    )
  }

  nodeMatcher (query: string, node: TreeNode): boolean {
    const selectedTypes = this.getSelectedEnergyTypes
    const search = query.substring(0, query.indexOf('&selected:'))
    const searchHit =
      node.data.meter.displayName.toLowerCase().indexOf(search.toLowerCase()) >
      -1
    const typeHit =
      node.data.meter.type !== undefined
        ? selectedTypes.indexOf(node.data.meter.type.toString()) > -1
        : true
    return searchHit && (selectedTypes.length === 0 || typeHit)
  }

  @Watch('site')
  async setMeters () {
    // console.log('site meter selector1', _.clone(this.siteMap[this.siteUuid].displayName))
    // console.log('site meter selector2', _.clone(this.siteMap[this.selectedSite].displayName))
    this.mainSite = this.siteUuid
    this.visitedSites = []
    this.selectedSite = this.siteUuid
  }

  @Watch('selectedSite')
  async onSiteChange (selectedSite: string): Promise<void> {
    // console.log('selectedSite selector', _.clone(this.siteMap[this.selectedSite].displayName))
    if (this.visitedSites.indexOf(this.siteMap[selectedSite]) === -1) {
      this.visitedSites.unshift(this.siteMap[selectedSite])
    }
    this.addManyMeters({
      meters: this.siteMap[selectedSite].meters,
      siteUuid: selectedSite
    })
  }

  async setFavoriteMetersOnSite () {
    this.favoriteMetersUuids = _.map(_.filter(this.user.favoriteSensors, f => {
      return f.siteUuid === this.selectedSite
    }), 'uuid') || []
  }

  async mapTreeviewMeters () {
    const items: TreeNode[] = []
    // console.log('mapTreeviewMeters')

    if (this.getTreeView) {
      // Tree-view
      _.forEach(this.hierarchy, (m) => {
        items.push(this.treeviewItems(m))
      })
    } else {
      // List-view
      _.forEach(this.getMeters, (m) => {
        items.push({
          data: { meter: m },
          text: m.displayName,
          state: {}
        })
      })
    }
    // Set default selected meters
    await this.setDefaultSelectedMeters()

    this.updateTreeData(items)
  }

  async setDefaultSelectedMeters () {
    if (this.localSelected.length === 0 &&
        this.visitedSites.length === 1 &&
        this.useFavorites) {
      await this.setFavoriteMetersOnSite()
      let mainAdded = false
      // Use favorites as default if there is any
      if (this.favoriteMetersUuids.length > 0) {
        _.forEach(this.getMeters, (meter) => {
          if (this.favoriteMetersUuids.indexOf(meter.uuid) > -1) {
            this.addMeter({ siteUuid: this.selectedSite, meter: meter })
            mainAdded = true
          }
        })
      }

      if (!mainAdded) {
        // If no favorites on site, use all main-meters
        _.forEach(this.getMeters, (meter) => {
          if (meter.main) {
            mainAdded = true
            this.addMeter({ siteUuid: this.selectedSite, meter: meter })
          }
        })
      }
      if (!mainAdded) {
        // If no meter is main, add oldest
        this.addMeter({
          siteUuid: this.selectedSite,
          meter: this.getMeters[0]
        })
      }
      this.$emit('updatePlot', this.localSelected)
    }
  }

  treeviewItems (n: any): TreeNode {
    // console.log('treeviewItems', n)
    const meter = _.find(this.getMeters, ['uuid', n.meterUuid])
    let name = meter?.displayName || n.displayName

    if (!name || name === '') name = '<mangler navn>'

    const newNode = {
      data: { meter: meter || (n as Meter), uuid: n.uuid },
      state: {},
      text: name,
      children: []
    } as TreeNode
    _.forEach(n.children, (c) => {
      if (newNode.children) {
        newNode.children.push(this.treeviewItems(c))
      }
    })
    return newNode
  }

  getNodeIcon (item: Meter) {
    if (!item.type && item.type !== 0) return 'mdi-file-tree'

    switch (item.type) {
      case 0:
        return 'bolt' // electrical
      case 1:
        return 'mdi-water' // water
      case 2:
        return 'mdi-barrel' // oil
      case 3:
        return 'mdi-gas-station' // diesel
      default:
        return '' // new energy type
    }
  }

  /**
   * For the whole tree:
   * None = -1
   * Some but not all = 0
   * All = 1
   */
  get isAnySomeAllSelected (): number {
    const countSelectedOnSite = _.filter(this.localSelected, s => {
      return s.siteUuid === this.selectedSite
    }).length
    if (countSelectedOnSite === 0) return -1
    else if (countSelectedOnSite < this.getMeters.length) return 0
    return 1
  }

  /**
   * For children of node:
   * None = -1
   * Some but not all = 0
   * All = 1
 */
  isAnySomeAllCSelected (node: TreeNode): number {
    const isSensor = node.data.meter.type !== undefined
    let checkedFound = false
    let uncheckedFound = false
    if (isSensor) {
      checkedFound = node.states?.checked || false
      uncheckedFound = !node.states?.checked || false
    }
    _.forEach(node.children, c => {
      if (c.children !== undefined && c.children.length > 0) {
        const cCheck = this.isAnySomeAllCSelected(c)
        if (cCheck >= 0) {
          checkedFound = true
        }
        if (cCheck <= 0) {
          uncheckedFound = true
        }
      } else if (c.states?.checked) {
        checkedFound = true
      } else if (!c.states?.checked) {
        uncheckedFound = true
      }
    })

    if (checkedFound && !uncheckedFound) return 1
    if (checkedFound && uncheckedFound) return 0

    return -1
  }

  /**
   * Array of visited sites and the number of meters selected on them
   */
  get visitedSitesWCount (): object[] {
    const meterCount = _.countBy(this.localSelected, (sm) => sm.siteUuid)
    return _.map(this.visitedSites, (s) => {
      return {
        displayName: s.displayName,
        count: meterCount[s.uuid],
        siteUuid: s.uuid
      }
    })
  }

  isFavoriteMeter (node: TreeNode) {
    return this.favoriteMetersUuids.indexOf(node.data.meter.uuid) > -1
  }

  async toggleFavorite (node: TreeNode) {
    const isFavorite = this.isFavoriteMeter(node)
    if (isFavorite) {
      await this.removeFavoriteSensor({
        site: this.siteMap[this.selectedSite].name,
        name: node.data.meter.name || ''
      }).then((success) => {
        if (success) {
          this.favoriteMetersUuids = _.remove(this.favoriteMetersUuids, (u) => {
            return u !== node.data.meter.uuid
          })
        }
      })
    } else {
      if (this.favoriteMetersUuids.length >= 20) {
        alert('Maks 20 favoritter per bygg')
      } else {
        await this.addFavoriteSensor({
          site: this.siteMap[this.selectedSite].name,
          name: node.data.meter.name || ''
        }).then((success) => {
          if (success) {
            this.favoriteMetersUuids.push(node.data.meter.uuid)
          }
        })
      }
    }
  }

  collapseAll () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.$refs.tree.tree.collapseAll()
  }

  expandAll () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.$refs.tree.tree.expandAll()
  }

  selectChildren (node: any, check = true) {
    let isSensor = node.data.meter.type !== undefined
    if (check && isSensor) {
      this.checkMeter(check, node.data.meter)
      node.check()
    } else if (!check && isSensor) {
      this.checkMeter(check, node.data.meter)
      node.uncheck()
    }
    _.forEach(node.children, c => {
      isSensor = c.data.meter.type !== undefined
      if (c.children.length > 0) {
        this.selectChildren(c, check)
      } else {
        if (check && isSensor) {
          this.checkMeter(check, c.data.meter)
          c.check()
        } else if (!check && isSensor) {
          this.checkMeter(check, c.data.meter)
          c.uncheck()
        }
      }
    })
  }

  selectAll (event: object | null, check = true) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.$refs.tree.tree.recurseDown((node) => {
      // Some nodes are just hierarchy-groups
      const isSensor = node.data.meter.type !== undefined
      if (isSensor) {
        if (check) {
          this.checkMeter(check, node.data.meter)
          node.check()
        } else if (!check) {
          this.checkMeter(check, node.data.meter)
          node.uncheck()
        }
      }
    })
  }

  unselectAll () {
    this.selectAll(null, false)
  }

  unselectAllOnSite (siteUuid: string) {
    if (siteUuid === this.getSelectedSiteUuid) {
      // Tree nodes needs to be unchecked
      this.unselectAll()
    } else {
      // Doesn't remove from visited sites if the site is currently selected
      _.remove(this.visitedSites, (s) => {
        return s.uuid === siteUuid
      })
      _.forEach(this.localSelected, (m) => {
        if (m.siteUuid === siteUuid) {
          this.checkMeter(false, m.meter)
        }
      })
    }
  }

  mounted () {
    console.log('mounted selector')
  }

  beforeDestroy () {
    console.log('beforeDestroy selector')
  }

  @Watch('getTreeView')
  toggleTreeView () {
    this.forceTreeUpdate()
  }

  @Watch('hierarchy')
  forceTreeUpdate () {
    this.mapTreeviewMeters()
    this.treeKey = !this.treeKey
  }

  useSelected () {
    if (this.localSelected.length <= this.maxSelectedMeter) {
      this.$emit('updatePlot', this.localSelected)
      this.open = false
    } else {
      this.snackbar = true
      this.snackbarText =
        `Du kan velge maksimalt ${this.maxSelectedMeter} målere, 
        du har valgt ${this.localSelected.length} målere.`
    }
  }

  get selectedSite (): string {
    return this.getSelectedSiteUuid
  }

  set selectedSite (uuid: string) {
    this.$emit('update:selectedSite', uuid)
  }

  get site (): Site {
    return this.siteMap[this.siteUuid]
  }

  get localValue (): SiteMeter[] {
    return this.localSelected
  }

  set localValue (localSelected: SiteMeter[]) {
    this.localSelected = localSelected
  }

  get open () {
    return this.value
  }

  set open (localValue) {
    this.$emit('input', localValue)
  }
}
