




import Vue from 'vue'

import { Prop, Component, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'

import * as _ from 'lodash'
import moment from 'moment-timezone'

import { TagStoreState, Tag } from '../../store/modules/tag_store/typedef'
import { Trace, TagData, PlotConfig } from '../typedef'

@Component({})
export default class PlotDataManager extends Vue {
  @Prop() settings!: PlotConfig;
  @State('TagStore') tagStore!: TagStoreState;
  @Prop({ default: 'Europe/Berlin' }) tz!: string;

  timeFormat = 'YYYY-MM-DD HH:mm:ss';
  name = 'PlotDataManager';

  raw: TagData = {};

  updateData (raw: TagData) {
    this.raw = raw

    return this.formatted
  }

  patchData (patch: TagData) {
    // track changes to avoid unneccesary updates of graph
    let changed = false

    const patched = _.mapValues(this.raw, (meter, uuid) => {
      // filter expired points from raw
      const valid = _.dropWhile(meter, point => {
        return moment.tz(point.timestamp, this.tz).isBefore(this.settings.from)
      })

      const meterPatch = patch[uuid]
      if (!patch) return valid

      // filter points in patch that already exist
      const last = moment.tz(
        _.get(_.last(meter), 'timestamp') || moment(0),
        this.tz
      )

      const newPoints = _.filter(meterPatch, point => {
        return moment.tz(point.timestamp, this.tz).isAfter(last)
      })

      // if any points removed or newpoints added
      if (valid.length !== meter.length || newPoints.length) changed = true

      return valid.concat(newPoints)
    })

    if (changed) this.raw = patched

    return this.formatted
  }

  get formatted (): Array<Trace> {
    return _.map(this.raw, (dataPoints, uuid) => {
      const tag = this.tagStore.tagMap[uuid]

      return {
        x: _.map(dataPoints, point => {
          return moment.tz(point.timestamp, this.tz).format(this.timeFormat)
        }),
        y: _.map(dataPoints, 'value'),
        name: (tag as Tag).name,
        uuid,
        visible: true,
        line: {
          shape: 'spline'
        },
        type: 'scatter'
      }
    })
  }

  @Watch('formatted')
  onFormattedChanged (formatted: Trace[]) {
    this.$emit('input', formatted)
  }
}
