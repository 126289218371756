import { Module, ActionContext } from 'vuex'
import { RootState } from '../../typedef'
import { BaseState } from './typedef'

import EPouch, { pouchKeys } from '@/lib/state_loader'

import { Site } from '../site_store/typedef'

const pouch = new EPouch()

const state: BaseState = {
  currentSiteUuid: '',
  theme: ''
}

const namespaced = true

export const BaseStore: Module<BaseState, RootState> = {
  namespaced,
  state,
  actions: {
    async siteInfo (
      action: ActionContext<BaseState, RootState>
    ): Promise<Site> {
      return action.dispatch(
        'SiteStore/get',
        action.state.currentSiteUuid
      )
    }
  },
  mutations: {
    changeSite (state, siteUuid) {
      state.currentSiteUuid = siteUuid
      pouch.putValue(pouchKeys.currentSiteUuid, siteUuid)
    },
    setTheme (state, theme) {
      state.theme = theme
    }
  }
}
