import PouchDB from 'pouchdb'
import _ from 'lodash'

import { Site } from '@/store/modules/site_store/typedef'
import moment from 'moment'

export const pouchKeys = {
  currentSiteUuid: 'esky_currentSiteUuid',
  lastSite: 'esky_lastSite',
  lastConf: 'esky_lastConf',
  lastPlotterConf: 'esky_lastPlotterConf',
  consumptionPlotterConf: 'esky_consumptionPlotterConf',
  durationPlotterConf: 'esky_durationPlotterConf',
  comparePlotterConf: 'esky_comparePlotterConf',
  etcurvePlotterConf: 'esky_etcurvePlotterConf',
  lastPlotterData: 'esky_lastPlotterData',
  lastUser: 'esky_lastUser',
  lastSites: 'esky_lastSites',
  lastSitesMap: 'esky_lastSites_map'
}

interface PouchResponse {
  _id: string;
  _rev: string;
  data: unknown;
  expirationDate: number;
}

interface SiteMap {
  [uuid: string]: Site;
}
export default class EPouch {
  db: PouchDB.Database
  private persistDuration = 24
  constructor(
    private name = 'eskyPouch',
    private size = 10
  ) {
    this.db = new PouchDB(name, { size })// { adapter: 'idb', size })
  }

  async getValue (key: string): Promise<unknown | null> {
    try {
      const res = await this.db.get(key) as PouchResponse
      const now: number = +moment().format('x')
      if (now > res.expirationDate) return null
      else return res.data
    } catch (error) {
      if (error.status === 404) return null
      else throw error
    }
  }

  async putValue (key: string, value: unknown) {
    const expirationDate = moment().add(this.persistDuration, 'h').format('x')

    try {
      const existing = await this.db.get(key)

      return this.db.put({
        _id: key,
        _rev: existing._rev,
        data: value,
        expirationDate: expirationDate
      })
    } catch (error) {
      console.warn('ePouch.putValue', error)
      return this.db.put({
        _id: key,
        data: value,
        expirationDate: expirationDate
      }).catch(err => console.error('ePouch.putValue.catch', err))
    }
  }

  async getPersistantState (): Promise<[string, Site[], SiteMap]> {
    const [currentSiteRaw, sitesArrayRaw] = await Promise.all([
      this.getValue(pouchKeys.currentSiteUuid),
      this.getValue(pouchKeys.lastSites)
    ])
    const currentSite: string = currentSiteRaw as string
    const sitesArray: Site[] = sitesArrayRaw as Site[]

    // create siteMap for array when
    // if (!siteMap) {
    // console.log('siteMap doesnt exist, creating from sitesArray')
    const siteMap = _.reduce(
      sitesArray,
      (
        result: { [uuid: string]: Site },
        site: Site
      ): { [uuid: string]: Site } => {
        result[site.uuid] = site

        return result
      },
      {}
    )

    // console.log('peristant state', currentSite, sitesArray.length)

    return [currentSite, sitesArray, siteMap]
  }
}
