/* global
ioSocket
*/
import { Module, ActionContext } from 'vuex'

import { Site, SiteStoreState } from './typedef'

import { RootState } from '@/store/typedef'
import EPouch, { pouchKeys } from '@/lib/state_loader'

const pouch = new EPouch()

const siteMap: { [uuid: string]: Site } = {}

const state: SiteStoreState = {
  siteMap
}

const namespaced = true

export const SiteStore: Module<SiteStoreState, RootState> = {
  namespaced,
  state,
  mutations: {
    // integration with existing sitesArray
    siteMap (state, sites) {
      // @temp freeze siteMap to avoid a million of getters or setters
      // saves ~2s for admins nte
      state.siteMap = Object.freeze(sites)
    },
    add (state, rawSite: Site) {
      console.error('siteMap frozen')

      state.siteMap[rawSite.uuid] = rawSite
    }
  },
  // getters,
  actions: {
    async get (
      action: ActionContext<SiteStoreState, RootState>,
      uuid: string
    ): Promise<Site> {
      const site = action.state.siteMap[uuid]

      if (site) return site

      return ioSocket
        .get('/Sites/get', { sites: uuid })
        .catch((error: Error) => {
          console.error('read site', error.message)

          throw Error('could not read tag')
        })
        .then((site) => {
          action.commit('add', site)

          return site as Site
        })
    },
    async forcedGet (
      action: ActionContext<SiteStoreState, RootState>,
      uuid: string
    ): Promise<Site[]> {
      return ioSocket
        .get('/Sites/get', { sites: uuid })
        .catch((error: Error) => {
          console.error('read site', error.message)
          throw Error('could not read tag')
        })
        .then((site) => {
          return site as Site[]
        })
    },
    async setActiveState (
      action: ActionContext<SiteStoreState, RootState>,
      uuid: string
    ): Promise<Site> {
      return ioSocket
        .get('/Sites/setActive', { site: uuid })
        .catch((error: Error) => {
          console.error('setActiveState', error.message)
        })
        .then((site) => {
          // changes the value in the sitemap site-object
          Promise.resolve(action.state.siteMap[uuid]).then(s => {
            s.active = true
            pouch.putValue(
              pouchKeys.lastSites,
              action.state.siteMap)
          })

          return site as Site
        })
    }
  }
}
