



















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class SmallBtn extends Vue {
  @Prop() buttonName!: string;
  @Prop() icon!: string;
  @Prop({ default: 'primary' }) color!: string;
  @Prop({ default: false }) outlined!: boolean;
  @Prop({ default: false }) disabled!: boolean;

  elevation = '0';
}
