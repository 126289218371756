





























/* global
    eTheme
*/
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import moment from 'moment'

import { mapState, mapMutations } from 'vuex'

import store from '@/store'
import EPouch from '@/lib/state_loader'

// components
import EToolbar from '@/components/e-toolbar.vue'
import ESideNav from '@/components/e-side-nav.vue'

import { Site } from '@/store/modules/site_store/typedef'
moment.locale('nb')

// start query for app state
const pouch = new EPouch()
const appStateQ = pouch.getPersistantState()

@Component({
  components: {
    EToolbar,
    ESideNav
  },
  store,
  computed: {
    ...mapState('BaseStore', { siteUuid: 'currentSiteUuid' }),
    ...mapState('SiteStore', { siteMap: 'siteMap' })
  },
  methods: mapMutations('BaseStore', {
    setTheme: 'setTheme',
    changeSite: 'changeSite'
  })
})
export default class EApp extends Vue {
  @Prop() pageName!: string;
  @Prop({ default: false, type: Boolean }) siteless!: boolean;

  siteUuid!: string;
  siteMap!: {[uuid: string]: Site};

  settings: object = {};
  tags = [];

  loading = true;
  sideNavOpen = false;
  errorMessage = '';
  baseTitle: string = eTheme === 'enoco' ? 'Eurora' : 'NTE EOS';

  setTheme!: (eTheme: string) => never
  changeSite!: (currentSiteUuid: string) => never

  get site (): Site {
    return this.siteMap[this.siteUuid]
  }

  get screenUHD () {
    return false // this.$vuetify.breakpoint.width > 1920
  }

  get textSize () {
    let result
    if (this.screenUHD) {
      result = 'screen-xl' // Why??
    }
    return result
  }

  get pageTitle (): string {
    return `${this.baseTitle} | ${this.pageName}`
  }

  mounted () {
    this.setTheme(eTheme)

    this.baseStartup()
  }

  /**
   * waits for main script to assign sitesArray and currentSiteUuid
   */
  async baseStartup () {
    // start query for current user
    const userQ = store.dispatch('UserStore/fetchUser')

    const persistentState = await appStateQ
    const currentSiteUuid: string = persistentState[0]
    const siteMap: { [uuid: string]: Site } = persistentState[2]

    store.commit('SiteStore/siteMap', siteMap)
    const url = new URL(window.location.toString())
    const urlUuid = url.searchParams.get('site')

    this.changeSite(urlUuid?.length === 36 ? urlUuid : currentSiteUuid)

    // await to preserve concurrency, loading bars etc.
    try {
      await userQ
    } catch (error) {
      console.error('current user load', error)
    }

    this.loading = false
  }
}
