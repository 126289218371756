











import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class ESelector extends Vue {
  @Prop() value!: string;
  @Prop() options!: Array<object>;
  @Prop() label!: string;

  get localValue () {
    return this.value
  }

  set localValue (localValue) {
    this.$emit('input', localValue)
  }
}
