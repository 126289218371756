/* global
ioSocket
*/
import _ from 'lodash'
import { DateTime } from 'luxon'
import { ActionContext, Module } from 'vuex'
import { RootState } from '../../typedef'
import { MeterDataTemp } from '../eos_plot_store/typedef'
import { CO2eStoreState, TypeUnitCO2e } from './typedef'

const namespaced = true

export const CO2eStore: Module<CO2eStoreState, RootState> = {
  namespaced,
  state: {
    // to: DateTime.local().startOf('year').minus({ months: 7 }).toISODate(),
    // from: DateTime.local().startOf('year').minus({ months: 11 }).toISODate(),
    to: DateTime.local().minus({ months: 1 }).endOf('month').toISODate(),
    from: DateTime.local().minus({ months: 1 }).startOf('month').toISODate(),
    res: 'days',
    graphStyle: 'bar',
    loadingData: false,
    prArea: false,
    stacked: true,
    typeUnitCO2e: [],
    siteTypeUnitCO2e: [],
    cumulative: false,
    typeUnitCO2eDefaults: [
      {
        unit: 'kWh',
        type: 'Elektrisk',
        co2e: 17
      },
      {
        unit: 'L',
        type: 'Diesel',
        co2e: 2660
      },
      {
        unit: 'L',
        type: 'Olje',
        co2e: 0
      },
      {
        unit: 'm³',
        type: 'Vann',
        co2e: 0
      },
      {
        unit: 'kWh',
        type: 'Vann',
        co2e: 0
      }
    ]
  },
  mutations: {
    updateToDate (state, date) {
      state.to = date
    },
    updateFromDate (state, date) {
      state.from = date
    },
    updateLoadingData (state, isLoading) {
      state.loadingData = isLoading
    },
    updateResolution (state, res) {
      state.res = res
    },
    updateCumulative (state, cumulative) {
      state.cumulative = cumulative
    },
    updateStacked (state, stacked) {
      state.stacked = stacked
    },
    updateGraphStyle (state, style) {
      state.graphStyle = style
    },
    updatePrArea (state, prArea) {
      state.prArea = prArea
    },
    updateCO2eValue (state, up) {
      const i = _.findIndex(state.typeUnitCO2e, x => {
        return x.type === up.type && x.unit === up.unit
      })
      if (i > -1) {
        state.typeUnitCO2e[i].co2e = up.co2e
      } else {
        state.typeUnitCO2e.push(up)
      }
    },
    updateTypeUnitCO2e (state, obj) {
      state.typeUnitCO2e = obj
    },
    addCO2eValue (state, newCo2e) {
      const i = _.findIndex(state.typeUnitCO2e, x => {
        return x.type === newCo2e.type && x.unit === newCo2e.unit
      })
      if (i === -1) {
        // console.log('siteTypeUnitCO2e', _.cloneDeep(state.siteTypeUnitCO2e))
        if (newCo2e.co2e === -1) {
          const si = _.findIndex(state.siteTypeUnitCO2e, x => {
            return x.type === newCo2e.type && x.unit === newCo2e.unit
          })
          if (si > -1) {
            newCo2e.co2e = state.siteTypeUnitCO2e[si].co2e
          } else {
            const di = _.findIndex(state.typeUnitCO2eDefaults, x => {
              return x.type === newCo2e.type && x.unit === newCo2e.unit
            })
            newCo2e.co2e = di === -1 ? 0 : state.typeUnitCO2eDefaults[di].co2e
          }
        }
        state.typeUnitCO2e.push(newCo2e)
      }
    },
    updateSiteCo2e (state, siteCo2e) {
      state.siteTypeUnitCO2e = siteCo2e
    }
  },
  actions: {
    async fetchMeterData (
      action: ActionContext<CO2eStoreState, RootState>,
      uuids: string[]
    ): Promise<MeterDataTemp[]> {
      action.commit('updateLoadingData', true)
      const relations = await ioSocket.get(
        '/EosData/get',
        {
          from: action.state.from,
          to: action.state.to,
          meters: uuids,
          resolution: action.state.res
        }
      ) as MeterDataTemp[]
      action.commit('updateLoadingData', false)
      return relations
    },
    async fetchSiteCo2e (
      action: ActionContext<CO2eStoreState, RootState>,
      siteUuid: string
    ): Promise<TypeUnitCO2e[]> {
      const co2e = await ioSocket.get(
        '/Sites/getSiteCo2e',
        {
          siteUuid: siteUuid
        }
      ) as TypeUnitCO2e[]

      action.commit('updateSiteCo2e', co2e)

      return co2e
    },
    async setSiteCo2eValue (
      action: ActionContext<CO2eStoreState, RootState>,
      post: {co2e: TypeUnitCO2e; siteUuid: string}
    ): Promise<boolean> {
      console.log('SiteCo2e start')
      const result = await ioSocket.get(
        '/Sites/setSiteCo2e',
        {
          siteUuid: post.siteUuid,
          type: post.co2e.type,
          unit: post.co2e.unit,
          value: Number(post.co2e.co2e)
        }
      ).then(r => {
        console.log('SiteCo2e r', r)
        return r
      }) as boolean
      console.log('SiteCo2e result', result)
      // action.commit('updateSiteCo2e', result)

      return result
    }
  },
  getters: {
    getToDate: state => { return state.to },
    getFromDate: state => { return state.from },
    getPrArea: state => { return state.prArea },
    getResolution: state => { return state.res },
    getCumulative: state => { return state.cumulative },
    getStacked: state => { return state.stacked },
    getGraphStyle: state => { return state.graphStyle },
    getIsLoading: state => { return state.loadingData },
    getTypeUnitCO2e: state => { return state.typeUnitCO2e },
    getSiteTypeUnitCO2e: state => { return state.siteTypeUnitCO2e },
    getCO2eDefaults: state => { return state.typeUnitCO2eDefaults }
  }
}
export default CO2eStore
