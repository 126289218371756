
















































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import PlotBtn from '@/components/eos-plot/plot-btn.vue'
import { mapActions } from 'vuex'
import { dispTextSiteMeterPicker } from '../eos-plot/typedef'

@Component({
  components: {
    PlotBtn
  },
  methods: {
    ...mapActions('EosPlotStore', {
      createNode: 'createNode'
    })
  }
})
export default class MeterSelectorMenu extends Vue {
  @Prop({ default: false }) value!: boolean;
  @Prop({ required: true }) editMode!: boolean;
  @Prop({ required: true }) getSelectedSiteUuid!: string;
  @Prop({ required: true }) updateTreeView!: (treeView: boolean) => {};
  @Prop({ required: true }) updateSelectedEnergyTypes!: (types: number[]) => {};

  dispText = dispTextSiteMeterPicker.nb;

  // Variables
  energyTypes = ['electrical', 'water', 'oil', 'diesel'];
  listOrGroupMode = 'group';

  selectedEnergyTypes: number[] = [];
  drawer = true;
  fromDate = '';
  toDate = '';
  menu1 = false;
  menu2 = false;
  mini = true;

  elevation = '0';

  // Methods
  mounted () {
    console.log('mounted meter selector menu')
  }

  createNode!: () => Promise<object>
  async createNewNode () {
    if (this.getSelectedSiteUuid === '') return ''

    let newnode
    try {
      newnode = await this.createNode()
    } catch (error) {
      console.error(error)
      throw error
    }

    console.log('createNode', newnode)
  }

  @Watch('selectedEnergyTypes')
  setSelectedEnergyTypes () {
    this.updateSelectedEnergyTypes(this.selectedEnergyTypes)
  }

  @Watch('listOrGroupMode')
  setTreeView () {
    this.updateTreeView(this.listOrGroupMode === 'group')
  }

  @Watch('open')
  drawerWatch () {
    this.$emit('update:settingsDrawer', this.open)
    // console.log('drawer', this.open)
  }

  get open () {
    return this.value
  }

  set open (localValue) {
    this.$emit('input', localValue)
  }

  get editSwitch () {
    return this.editMode
  }

  set editSwitch (editSwitch) {
    this.$emit('updateEditMode', editSwitch)
  }
}
