/* global
ioSocket
colorArray
*/
import {
  FullSite,
  PlotNavOptions
} from '@/components/eos-plot/typedef'
import { ActionContext, Module } from 'vuex'
import { RootState } from '../../typedef'
import {
  ApiEtCurve,
  ApiEtCurveNl,
  EosPlotStoreState,
  ETCurve,
  ETCurveNl,
  Resolution,
  XYPoint
} from './typedef'
import * as _ from 'lodash'
import { DateTime } from 'luxon'
import { isArray, isEmpty } from 'lodash'

const namespaced = true

export const EosPlotStore: Module<EosPlotStoreState, RootState> = {
  namespaced,
  state: {
    selectedSiteUuid: '',
    selectedMeters: [],
    selectedSite: undefined,
    treeData: [],
    hierarchy: [],
    meters: [],
    selectedEnergyTypes: [],
    search: '',
    treeView: true,
    selectedDataRaw: [],
    editMode: false,
    plotConfigs: [
      { // consumption = 0
        resolution: Resolution.days,
        graphStyle: 'bar',
        cumulative: false,
        stacked: true,
        selectedMeterUuid: '',
        prArea: false,
        to: DateTime.local().toISODate(),
        from: DateTime.local().startOf('year').toISODate()
      },
      { // duration = 1
        resolution: Resolution.days,
        graphStyle: 'bar',
        cumulative: false,
        stacked: true,
        selectedMeterUuid: '',
        prArea: false,
        to: DateTime.local().toISODate(),
        from: DateTime.local().startOf('year').toISODate()
      },
      { // compare = 2
        resolution: Resolution.months,
        graphStyle: 'bar',
        cumulative: false,
        stacked: false,
        prArea: false,
        selectedMeterUuid: '',
        to: DateTime.local().toISODate(),
        from: DateTime.utc(2000).toISODate()
      },
      { // etcurve = 3
        resolution: Resolution.weeks,
        graphStyle: 'bar',
        cumulative: false,
        stacked: false,
        selectedMeterUuid: '',
        prArea: false,
        to: DateTime.local().toISODate(),
        from: DateTime.utc(2020).toISODate()
      }
    ],
    plotConfig: { // default
      resolution: Resolution.days,
      graphStyle: 'bar',
      cumulative: false,
      stacked: true,
      selectedMeterUuid: '',
      prArea: false,
      to: DateTime.local().toISODate(),
      from: DateTime.local().startOf('year').toISODate()
      // to: DateTime.local().startOf('year').minus({ months: 7 }).toISODate(),
      // from: DateTime.local().startOf('year').minus({ months: 11 }).toISODate()
    },
    plotNav: PlotNavOptions.consumption,
    selectedYears: [],
    selectedYearET: '',
    availableYears: [],
    drawETEnabled: false,
    newETPoints: [],
    etCurves: []
  },
  mutations: {
    updateNewETPoints (state, points) {
      state.newETPoints = points
    },
    updateDrawETEnabled (state, value) {
      state.drawETEnabled = value
    },
    updatePlotNav (state, nav) {
      state.plotConfigs[state.plotNav] = state.plotConfig
      state.plotNav = nav
      state.plotConfig = state.plotConfigs[nav]
    },
    updateSelectedYears (state, years) {
      state.selectedYears = years.sort().reverse()
    },
    updateSelectedYearET (state, year) {
      if (!isEmpty(year) && state.plotNav === PlotNavOptions.etcurve) {
        // console.log('updateSelectedYearET', year)
        state.selectedYearET = year
        // Data is fetched based on to and from dates in config
        state.plotConfig.from = DateTime.utc(Number(year))
          .startOf('year').toISODate()
        state.plotConfig.to = DateTime.utc(Number(year))
          .endOf('year').minus({ days: 1 }).toString()
      }
    },
    updateAvailableYears (state, years) {
      // console.log('updateAvailableYears store', years)
      state.availableYears = years.sort().reverse()
    },
    updateHierarchy (state, hir) {
      state.hierarchy = hir
    },
    updateMeters (state, meters) {
      state.meters = meters
    },
    updateTreeData (state, treeData) {
      state.treeData = treeData
    },
    updateEditMode (state, editMode) {
      state.editMode = editMode
    },
    updateSelectedEnergyTypes (state, types) {
      state.selectedEnergyTypes = types
    },
    updateTreeView (state, treeView) {
      state.treeView = treeView
    },
    updateSelectedDataRaw (state, data) {
      state.selectedDataRaw = data
    },
    updateSelectedSiteUuid (state, uuid) {
      state.selectedSiteUuid = uuid
    },
    updateSelectedMeters (state, selectedMeters) {
      state.selectedMeters = selectedMeters
    },
    addSelectedMeter (state, siteMeter) {
      // Prevent duplicates in array
      state.selectedMeters = _.unionBy(state.selectedMeters, [{
        siteUuid: siteMeter.siteUuid,
        meter: siteMeter.meter
      }], o => o.meter.uuid)
    },
    removeSelectedMeter (state, meter) {
      state.selectedMeters = _.remove(
        _.cloneDeep(state.selectedMeters), m => {
          return m.meter.uuid !== meter.uuid
        })
    },
    updateEtCurves (state, curves) {
      state.etCurves = curves
    },
    updateSettings (state, settings) {
      // console.log('set selectedMeter updateSettings', settings)
      state.plotConfig = settings
      state.plotConfigs[state.plotNav] = settings
    }
  },
  actions: {
    async loadTreeData (
      action: ActionContext<EosPlotStoreState, RootState>,
      uuid: string
    ): Promise<FullSite> {
      const relations = await ioSocket.get(
        '/Sites/get', { sites: [uuid] }).then(result => {
        const retSite = (result as FullSite[])[0]
        // console.log('retSite', retSite)

        action.commit('updateHierarchy', retSite.hierarchy)
        action.commit('updateMeters', retSite.meters)

        return retSite
      })
      return relations
    },
    async fetchAndUpdateAvailableYears (
      action: ActionContext<EosPlotStoreState, RootState>
    ): Promise<number[]> {
      const uuids = action.getters.getSelectedMetersUuids
      if (uuids.length === 0) return []

      console.log('updateAvailableYears action.getters.getSelectedMetersUuids',
        _.cloneDeep(action.getters.getSelectedMetersUuids))
      const years = await ioSocket.get(
        '/EosData/getFirst',
        {
          meters: uuids,
          resolution: 'days'
        }
      ).then(firstData => {
        const firstDate = DateTime.fromISO((firstData as any[])[0].value.ts)
        const yearDiff = DateTime.local().year - firstDate.year

        return _.times(yearDiff + 1, i => {
          return firstDate.year + i
        })
      })

      action.commit('updateAvailableYears', years.map(String))

      return years
    },
    async createNode (
      action: ActionContext<EosPlotStoreState, RootState>
    ): Promise<object> {
      const newNode = await ioSocket.get('/Hierarchy/createNode', {
        parent: {
          siteUuid: action.state.selectedSiteUuid
        },
        displayName: 'Ny gruppe'
      }).then(newNode => {
        action.dispatch('loadTreeData', action.state.selectedSiteUuid)

        return isArray(newNode) ? newNode[0] : newNode
      }).catch(function (error) {
        console.log(error)
      })

      return newNode as object
    },
    async removeNode (
      action: ActionContext<EosPlotStoreState, RootState>,
      uuid: string
    ): Promise<string> {
      const result = await ioSocket.get('/Hierarchy/removeNode', {
        uuid: uuid.toString()
      }).then(uuid => {
        // console.log('store removeNode', uuid)
        action.dispatch('loadTreeData', action.state.selectedSiteUuid)
        return uuid
      }).catch(function (error) {
        console.error(error)
      })
      return result as string
    },
    async fetchETCurveOld (
      action: ActionContext<EosPlotStoreState, RootState>
    ): Promise<ETCurve[]> {
      const et: ApiEtCurve[] = await ioSocket.get('/EosEtCurves/getUuid', {
        meterUuid: action.getters.getSelectedMetersUuids,
        resolution: action.getters.getResolution.slice(0, -1)
      }).catch(function () {
        console.warn('no et curves found')
      }) as ApiEtCurve[]
      // console.log('et kurve hentet', et)

      return et && et.length > 0 ? et[0].etCurves : []
    },
    async fetchETCurve (
      action: ActionContext<EosPlotStoreState, RootState>
    ): Promise<ETCurveNl[]> {
      const resolution = _.trimEnd(action.state.plotConfig.resolution, 's')
      const et: ApiEtCurveNl[] = await ioSocket
        .get('/EosEtCurves/getUuidNonlinear', {
          meterUuid: action.getters.getSelectedMetersUuids,
          resolution: resolution
        }).catch(function () {
          console.warn('no et curves found')
        }) as ApiEtCurveNl[]
      const curves = et && et.length > 0 ? et[0].etCurves : []
      _.map(curves, et => {
        et.color = colorArray[et.year - 2010]
      })
      // console.log('getUuidNonlinear hentet ', curves)
      action.commit('updateEtCurves', curves)
      return curves
    },
    async saveETPoints (
      action: ActionContext<EosPlotStoreState, RootState>,
      points: XYPoint[]
    ): Promise<void> {
      /*       if (points.length !== 2) {
        throw Error('new Et-curve must have 2 points')
      } */
      console.log('saveETPoints', points)
      return await ioSocket.get('/EosEtCurves/setPoints', { points })
        .then((r) => {
          console.log('et points lagret saveETPoints', r)
        }).catch(function (error) {
          console.error(error)
        })
    },
    async saveETCurve (
      action: ActionContext<EosPlotStoreState, RootState>,
      points: XYPoint[]
    ): Promise<boolean> {
      const resolution = _.trimEnd(action.state.plotConfig.resolution, 's')

      const etCurve = {
        meterUuid: action.state.plotConfig.selectedMeterUuid,
        year: Number(action.state.selectedYearET),
        resolution: resolution,
        inactive: false,
        points: points
      }
      console.log('et points lagret saveETCurve', etCurve)
      const newEt = await ioSocket.get('/EosEtCurves/setUuidNonlinear', etCurve)
        .catch(function (error) {
          console.error(error)
        }) as boolean
      return newEt
    },
    async destroyETCurve (
      action: ActionContext<EosPlotStoreState, RootState>,
      id: number
    ): Promise<object> {
      console.log('store destroyETCurve', id)
      const res = await ioSocket.get('/EosEtCurves/destroyIdNonlinear', {
        meterUuid: action.state.plotConfig.selectedMeterUuid,
        id: id
      }).then(count => {
        console.log('destroyETCurve count', count)
        action.dispatch('fetchETCurve')
        return count
      })
        .catch(function (error) {
          console.error(error)
        }) as object
      return res
    }
  },
  getters: {
    getPlotNav: state => { return state.plotNav },
    getSelectedSiteUuid: state => { return state.selectedSiteUuid },
    getMeters: (state) => { return state.meters },
    getSelectedEnergyTypes: state => { return state.selectedEnergyTypes },
    getTreeView: state => { return state.treeView },
    getSelectedMeters: state => { return state.selectedMeters },
    getSelectedDataRaw: state => { return state.selectedDataRaw },
    getDrawETEnabled: state => { return state.drawETEnabled },
    getNewETPoints: state => { return state.newETPoints },
    getResolution: state => {
      return state.plotConfig.resolution
    },
    getGraphStyle: state => {
      return state.plotConfig.graphStyle
    },
    getEditMode: state => { return state.editMode },
    getAvailableYears: state => { return state.availableYears },
    getSelectedYears: state => {
      return state.selectedYears
    },
    getSelectedYearET: state => { return state.selectedYearET },
    getSelectedSingelMeter: state => {
      return state.plotConfig.selectedMeterUuid
    },
    getSelectedMetersUuids: state => {
      if (state.plotNav > 1) {
        return state.plotConfig.selectedMeterUuid !== ''
          ? [state.plotConfig.selectedMeterUuid] : []
      } else {
        return _.flatMap(state.selectedMeters, m => {
          return m.meter.uuid
        })
      }
    },
    getSettings: state => { return state.plotConfig },
    getEtCurves: state => { return state.etCurves },
    getTreeData: (state) => { return state.treeData }
  }
}
export default EosPlotStore
