import { Site } from '@/store/modules/site_store/typedef'
import { User } from '@/store/modules/user/'
import _ from 'lodash'

type AccessArea = 'alarm' | 'cal' | 'carCharge' | 'demo' | 'eco' | 'emg' |
  'energyGuard' | 'eos' | 'realtime' | 'siteAdmin' | 'surveillance' | 'admin'

export interface MenuItem {
  icon: string;
  name: string;
  key: string;
  link?: string;
  accessRequired?: AccessArea[];
  target?: string;

  children?: MenuItem[];
}

const menuItems: MenuItem[] = [
  {
    icon: 'house',
    name: 'Startside',
    link: '/homepage',
    key: 'homepage'
  },
  {
    icon: 'public',
    name: 'Mine bygg',
    link: '/siteOverview',
    key: 'siteoverview'
  },
  {
    icon: 'insert_chart',
    name: 'EOS',
    key: 'eos',
    children: [
      {
        link: '/eos/overview',
        name: 'Mitt bygg',
        icon: 'home',
        key: 'eos_overview',
        accessRequired: ['eos']
      },
      // {
      //   link: '/eos/plot',
      //   name: 'Plott',
      //   icon: 'bar_chart',
      //   key: 'eos_plot',
      //   accessRequired: ['eos']
      // },
      {
        link: '/eos/plot',
        name: 'Plott',
        icon: 'bar_chart',
        key: 'eos_plot',
        accessRequired: ['eos']
      },
      {
        link: '/tags/plot',
        name: 'Sanntid',
        icon: 'show_chart',
        key: 'eos_realtime',
        accessRequired: ['realtime']
      },
      {
        link: '/eos/meters',
        name: 'Alle målere',
        icon: 'list',
        key: 'eos_meters',
        accessRequired: ['eos']
      },
      {
        link: '/eos/innvoice',
        name: 'Økonomi',
        icon: 'attach_money',
        key: 'eos_innvoice',
        accessRequired: ['eco']
      },
      {
        icon: 'calculate',
        name: 'Beregn bruk per enhet',
        link: '/eos/table_math',
        key: 'table_math',
        accessRequired: ['eos']
      },
      {
        icon: 'calculate',
        name: 'Beregn CO2-ekvivalenter',
        link: '/eos/co2e',
        key: 'co2e',
        accessRequired: ['eos']
      },
      /* {
        icon: 'folder',
        name: 'Rapport (v2.0)',
        link: '/eos/report',
        key: 'report',
        accessRequired: ['eos']
      }, */
      {
        icon: 'list',
        name: 'Mine Bygg',
        link: '/eos/sites',
        key: 'my_sites',
        accessRequired: ['eos']
      },
      // {
      //   icon: 'edit',
      //   name: 'Manuell målerdata',
      //   link: '/eos/data_input',
      //   key: 'dataInput',
      //   accessRequired: ['eos']
      // },
      {
        link: '/eos/alarms',
        name: 'Alarmer',
        icon: 'notifications',
        key: 'eos_alarms',
        accessRequired: ['alarm']
      }
    ]
  },
  {
    icon: 'star',
    name: 'Demo',
    key: 'demo',
    accessRequired: ['demo'],
    children: [
      {
        link: '/solardemo',
        name: 'Soldemo',
        icon: 'wb_sunny',
        key: 'demo_solar'
      },
      {
        link: '/marindata',
        name: 'Marindemo',
        icon: 'directions_boat',
        key: 'demo_marine'
      }
    ]
  },
  {
    icon: 'directions_run',
    name: 'Nødlys',
    link: '/emg',
    key: 'emg',
    accessRequired: ['emg']
  },
  // {
  //   icon: 'event_available',
  //   name: 'Bookingkalender',
  //   link: '/ecalendar',
  //   key: 'cal',
  //   accessRequired: ['cal']
  // },
  {
    icon: 'event_available',
    name: 'Bookingkalender',
    link: '/views/ecalendar',
    key: 'cal2',
    accessRequired: ['cal']
  },
  {
    icon: 'settings_applications',
    name: 'Innstillinger',
    key: 'settings',
    children: [
      {
        link: '/adminSettings',
        name: 'Admin',
        icon: 'supervisor_account',
        key: 'settings_admin',
        accessRequired: ['admin']
      },
      // {
      //   name: 'AdminNew',
      //   link: '/adminSettings/admin_new',
      //   icon: 'supervisor_account',
      //   key: 'settings_admin_new',
      //   accessRequired: ['admin']q
      // },
      {
        name: 'IoT Admin',
        link: '/adminSettings/iotAdminBeta',
        icon: 'supervisor_account',
        key: 'settings_iotAdminBeta',
        accessRequired: ['admin']
      },
      /* {
        name: 'AdminNew',
        link: '/adminSettings/admin_new',
        icon: 'supervisor_account',
        key: 'settings_admin_new',
        accessRequired: ['admin']
      }, */
      // {
      //   name: 'Devices',
      //   link: '/adminSettings/iot_users_dashboard',
      //   icon: 'device_hub',
      //   key: 'settings_iot_users',
      //   accessRequired: ['admin']
      // },
      {
        name: 'Rediger målerverdier',
        link: '/adminSettings/edit_meters',
        icon: 'list',
        key: 'settings_edit_meters',
        accessRequired: ['admin']
      },
      {
        link: '/views/user_controlpanel',
        name: 'Bruker',
        icon: 'account_circle',
        key: 'settings_user'
      },
      {
        name: 'Licenses',
        link: '/adminSettings/licenses',
        icon: 'supervisor_account',
        key: 'settings_licenses',
        accessRequired: ['admin']
      }
    ]
  },
  {
    icon: 'file_download',
    name: 'Brukermanual',
    link: 'https://eurora-manual.s3.eu-central-1.amazonaws.com/Produktbeskrivelse+og+brukermanual+Eurora.pdf',
    key: 'manual',
    target: '_blank'
  }
]

export function filterMenuItems (
  site: Site,
  user: User,
  inputItems: MenuItem[] = menuItems
): MenuItem[] {
  const items: MenuItem[] = []
  const isAdmin = _.includes(user.roles, 'admin')

  function hasAccess (area: AccessArea) {
    if (area === 'admin') return isAdmin

    return site[area] === 'basic' ||
      site[area] === 'full'
  }

  inputItems.forEach(item => {
    let access = true

    if (item.accessRequired) access = item.accessRequired.every(hasAccess)
    if (!access) return

    // to avoid mutation of original list
    const itemCopy = _.clone(item)

    if (item.children) {
      const children = filterMenuItems(site, user, item.children)
      // item groups/parents with no children after filtering are also filtered
      if (children.length) {
        itemCopy.children = children
        items.push(itemCopy)
      }
    } else {
      items.push(itemCopy)
    }
  })
  return items
}

export default menuItems
