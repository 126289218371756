























import Vue from 'vue'
import { mapState } from 'vuex'
import { Component, Prop } from 'vue-property-decorator'

import { SiteSearchItem } from './typedef'
import { Site } from '../../store/modules/site_store/typedef'

import * as _ from 'lodash'

@Component({
  components: {},
  computed: mapState('SiteStore', ['siteMap'])
})
export default class SiteSearch extends Vue {
  @Prop() readonly value!: string
  @Prop() readonly filter!: string
  @Prop() readonly multiple!: boolean
  @Prop({ default: false }) clearOnSelect!: boolean;
  @Prop({ default: false }) autofocus!: boolean;

  get localValue () {
    return this.value
  }

  /**
     * emits if localvalue exists
     */
  set localValue (localValue: string) {
    if (localValue) { this.$emit('input', localValue) }
  }

    siteMap!: Site[]
    get sites (): SiteSearchItem[] {
      const sites = (this.filter)
        ? _.filter(this.siteMap, (site: Site) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          const access = site[this.filter]

          return (access !== 'hidden' && access !== 'none')
        })
        : this.siteMap

      return _.map(sites, (site: Site) => {
        const text = _.compact([
          site.name,
          site.displayName,
          site.address,
          site.postCode,
          site.postLocation,
          site.description
        ]).join(' ')

        let address = _.compact([
          site.address,
          site.postCode,
          site.postLocation
        ]).join(' ')
        address = address ? `(${address})` : ''

        const displaySearch = `${site.displayName}, ${site.description || ''} ${address}`

        return {
          text,
          displaySearch,
          displaySelected: site.displayName,
          uuid: site.uuid
        }
      })
    }
}

