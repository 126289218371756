






























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { PlotNavOptions } from './typedef'

@Component
export default class EosPlotNav extends Vue {
  @Prop({ default: PlotNavOptions.consumption }) value!: number;
  @Prop({ default: false }) vertical!: boolean;
  @Prop({ default: false }) consumptionDisabled!: boolean;
  @Prop({ default: false }) durationDisabled!: boolean;
  @Prop({ default: false }) compareDisabled!: boolean;
  @Prop({ default: false }) etnDisabled!: boolean;

  get navValue (): number {
    return this.value
  }

  set navValue (value: number) {
    this.$emit('update:value', value)
  }
}
