import _ from 'lodash'

export interface ApiResource {
  name: number;
  displayName: string;
  comment: string | null;
  type: string | null;
  uuid: string;
  createdAt: string;
  updatedAt: string;
}
export interface ApiGroup {
  uuid: string;
  displayName: string;
  comment: string | null;
  type: number;
  members: string[];
}

export interface ApiEvent {
  title: string;
  from: string;
  to: string;
  exception: boolean;
  inactive: boolean;
  comment: string | null;
  uuid: string;
  createdAt?: string;
  updatedAt?: string;
  resource: string[];
  group: string[];
  createdBy?: string;

  interval_length?: number;
  interval_unit?: IntervalUnit;
  expiredAfter?: string;
}

export interface ApiAddEventInput {
  siteUuid: string;
  event: ApiEvent[];
}

export type IntervalUnit = 'day' | 'week' | 'month' | 'year';

export abstract class ResourceType {
  public static types = [
    { value: null, text: 'Annet' },
    { value: 0, text: 'Lys' },
    { value: 1, text: 'Varme' },
    { value: 2, text: 'Ventilasjon' }
  ];

  public static typeName (value: number | null): string | undefined {
    return _.find(ResourceType.types, ['value', value])?.text
  }
}
