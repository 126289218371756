import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { IntervalUnit, ApiEvent } from '../../../pages/ecalendar/typedef'
import BasicEvent from './basic_event'
import { VCalendarEvent } from './typedef'

/**
 * Checks if date of source exists in subject, and changes to same if it does.
 * mutates subjectDate
 * @param subjectDate Date to (possibly) mutate
 * @param sourceDate Source of date
 */
function setToSameDateIfExists (subjectDate: Moment, sourceDate: Moment) {
  if (
    !subjectDate.isSame(sourceDate, 'date') &&
    subjectDate.daysInMonth() >= sourceDate.date()
  ) {
    subjectDate.date(sourceDate.date())
  }
}
export default class RepeatEvent extends BasicEvent {
  expiredAfter?: Moment

  constructor (
    name: string,
    comment: string,
    start: Moment,
    end: Moment,
    exception: boolean,
    inactive: boolean,
    uuid: string,
    resource: string[],
    group: string[],
    tz: string,
    public intervalLength: number,
    public intervalUnit: IntervalUnit,
    expiredAfter?: Moment | null,
    updatedAt?: Moment,
    createdAt?: Moment,
    createdBy?: string
  ) {
    super(
      name,
      comment,
      start,
      end,
      exception,
      inactive,
      uuid,
      resource,
      group,
      tz,
      updatedAt,
      createdAt,
      createdBy
    )

    if (expiredAfter) this.expiredAfter = expiredAfter
  }

  copy (): RepeatEvent {
    return new RepeatEvent(
      this.name,
      this.comment,
      moment(this.start),
      moment(this.end),
      this.exception,
      this.inactive,
      this.uuid,
      _.clone(this.resource),
      _.clone(this.group),
      this.tz,
      this.intervalLength,
      this.intervalUnit,
      moment.isMoment(this.expiredAfter) ? moment(this.expiredAfter) : null,
      this.updatedAt,
      this.createdAt,
      this.createdBy
    )
  }

  eventsWithin (from: Moment, to: Moment): VCalendarEvent[] {
    const unit = this.intervalUnit
    const length = this.intervalLength

    // empty when first occurence after current window
    const firstAfterTo = this.start.isAfter(to)
    if (firstAfterTo) return []

    const diff = from.diff(this.start, unit, true)
    // no negatives (no events before base event)
    const repetitions = _.max([_.floor(diff / length), 0]) as number

    const firstRelevantStart =
      moment(this.start)
        .add(repetitions * length, unit)
    const firstRelevantEnd =
      moment(this.end)
        .add(repetitions * length, unit)

    // possible early abort expired

    const events: VCalendarEvent[] = []

    let after = false
    let expired = false
    let repeatStart = moment(firstRelevantStart)
    let repeatEnd = moment(firstRelevantEnd)
    while (!after && !expired) {
      // expired
      if (this.expiredAfter &&
        repeatStart.isSameOrAfter(this.expiredAfter)) { // .clone().endOf('day')
        expired = true
      } else if (repeatStart.isAfter(to)) { // after to
        after = true
      } else { // within
        if (repeatEnd.isAfter(from)) {
          events.push(this.generateVCalendarEvent(
            this.name,
            repeatStart,
            repeatEnd,
            this.tz
          ))
        }

        repeatStart.add(length, unit)
        repeatEnd.add(length, unit)

        if (unit === 'month' || unit === 'year') {
          setToSameDateIfExists(repeatStart, this.start)
          setToSameDateIfExists(repeatEnd, this.end)
        }
      }
    }

    return events
  }

  apiExport (): ApiEvent {
    return {
      title: this.name,
      from: this.start.toISOString(),
      to: this.end.toISOString(),
      exception: this.exception,
      inactive: this.inactive,
      comment: this.comment,
      uuid: this.uuid,
      resource: this.resource,
      group: this.group,

      interval_length: this.intervalLength,
      interval_unit: this.intervalUnit,
      expiredAfter: this.expiredAfter?.toISOString()
    }
  }
}
