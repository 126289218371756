



















































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import moment, { Moment } from 'moment-timezone'

@Component
export default class DatetimePicker extends Vue {
  @Prop() value!: Moment;
  @Prop() min!: Moment;
  @Prop() max!: Moment;
  @Prop({ default: 'Velg dato' }) label!: string;
  @Prop({ default: 'Europe/Berlin' }) tz!: string;

  dateMenu = false;
  timeMenu = false;

  get date () {
    return moment(this.value).format('YYYY-MM-DD')
  }

  set date (date) {
    this.localValue = moment.tz(date + ' ' + this.time, this.tz)
  }

  get time () {
    return moment(this.value).format('HH:mm')
  }

  set time (time) {
    this.localValue = moment.tz(this.date + ' ' + time, this.tz)
  }

  get maxDate () {
    if (this.max) return this.max.format('YYYY-MM-DD')
    return null
  }

  get minDate () {
    if (this.min) return this.min.format('YYYY-MM-DD')
    return null
  }

  get localValue () {
    return this.value
  }

  set localValue (localValue) {
    this.$emit('update:value', localValue)
  }
}
