







/* global
colorArray
*/
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import Plotly from './Plotly.vue'
import TagDataQuery, { TagData } from './TagDataQuery'
import PlotDataManager from './plot-data-manager.vue'

import { Moment, Duration } from 'moment'
import moment from 'moment-timezone'
import { PlotConfig, Trace, Resolution } from '../typedef'

import * as _ from 'lodash'

@Component({
  components: { Plotly, PlotDataManager }
})
export default class TagPlot extends Vue {
  @Prop() settings!: PlotConfig;
  @Prop() tags!: string[];
  @Prop({ default: 'Europe/Berlin' }) tz!: string;

  tagData: Array<Trace> = [];

  maxResolutionPoints: number = 10 * 1000;

  subscription: number | null = null;
  subscriptionDuration: Duration = moment.duration();

  // assigned during mounted step
  dataManager!: PlotDataManager;
  ignoreNextUpdate = false;
  /**
   * function moved to sidebar for tag-plot, remove? maybe it should be kept
   * for things like dashboards etc.
   */
  get resolution (): Resolution {
    if (this.settings.resolution && this.settings.resolution !== 'auto') { return this.settings.resolution }

    const secondDifference = moment(this.settings.to).diff(
      moment(this.settings.from),
      'seconds'
    )
    // or 1 for startup
    const dataPoints = (this.tags.length || 1) * secondDifference
    if (dataPoints < this.maxResolutionPoints) return 'seconds'
    else if (dataPoints / 60 < this.maxResolutionPoints) return 'minutes'
    else if (dataPoints / (60 * 60) < this.maxResolutionPoints) return 'hours'
    else return 'days'
  }

  get plotConfig () {
    return { data: this.tagData, layout: this.layout }
  }

  get layout () {
    return {
      title: null,
      colorway: _.map(colorArray),
      hovermode: 'closest', // when not 'closest' line bug appears when large amount of data.
      // autosize: true,
      showlegend: true,
      margin: {
        l: 60,
        r: 60,
        b: 60,
        t: 60,
        pad: 4
      },
      xaxis: {
        hovermode: 'closest',
        showspikes: false
      },
      yaxis: {
        title: '',
        type: '',
        side: 'left',
        showspikes: false,
        titlefont: { color: '#1f77b4' },
        tickfont: { color: '#1f77b4' }
      },
      yaxis2: {
        title: '',
        type: '',
        overlaying: 'y',
        side: 'right',
        anchor: 'x',
        showspikes: false,
        titlefont: { color: '#ff7f0e' },
        tickfont: { color: '#ff7f0e' }
      },
      yaxis3: {
        title: '',
        type: '',
        overlaying: 'y',
        side: 'left',
        anchor: 'free',
        position: 0,
        showspikes: false,
        titlefont: { color: '#2ca02c' },
        tickfont: { color: '#2ca02c' }
      },
      yaxis4: {
        title: '',
        type: '',
        overlaying: 'y',
        side: 'right',
        anchor: 'free',
        position: 1,
        showspikes: false,
        titlefont: { color: '#d62728' },
        tickfont: { color: '#d62728' }
      },
      legend: {
        orientation: 'h'
      },
      hoverlabel: {
        namelength: -1
      }
    }
  }

  get subscriptionInterval () {
    switch (this.resolution) {
      case 'seconds':
        return 1000
      case 'minutes':
        return 10 * 1000
      case 'hours':
      case 'days':
      case 'months':
      case 'years':
      default:
        return 60 * 1000
    }
  }

  mounted () {
    // next tick to ensure child component rendered
    this.$nextTick(function () {
      this.dataManager = this.$refs.dataManager as PlotDataManager
      this.update()
    })
  }

  update () {
    if (!this.tags.length) return console.warn('plot has no tags')
    // early return for when child component does not exist yet
    if (!this.dataManager) return

    return this.getData()
      .then((data: TagData) => {
        this.dataManager.updateData(data)
      })
      .catch(err => {
        // @todo
        console.error('could not get data:', err)
      })
  }

  liveUpdate () {
    if (!this.tags.length) return console.warn('plot has no tags')
    // early return for when child component does not exist yet
    if (!this.dataManager) return

    const newestValue = _.reduce(
      this.tagData,
      (result, tag) => {
        const last: Moment = moment.tz(_.last(tag.x), this.tz)

        return moment.min(result, last)
      },
      moment().add(1, 'd')
    )

    // mutate settings to avoid triggering watch settings
    this.settings.to = moment.tz(this.tz)
    this.settings.from = moment.tz(this.tz).subtract(this.subscriptionDuration)

    this.ignoreNextUpdate = true

    return this.getData(newestValue, moment())
      .then((data: TagData) => {
        this.dataManager.patchData(data)
      })
      .catch(err => {
        // @todo
        console.error('could not get data:', err)
      })
  }

  subscribe () {
    if (this.subscription) this.unsubscribe()

    this.subscriptionDuration = moment.duration(
      (this.settings.to as Moment).diff(this.settings.from)
    )

    this.subscription = setInterval(
      this.liveUpdate.bind(this),
      this.subscriptionInterval
    )
  }

  unsubscribe () {
    if (this.subscription) clearInterval(this.subscription)
  }

  getData (from?: Moment, to?: Moment): Promise<TagData> {
    console.log('get data res', this.resolution)

    const tagDataQuery = new TagDataQuery(
      this.tags,
      moment(from || this.settings.from),
      moment(to || this.settings.to),
      this.resolution
    )
    return tagDataQuery.get(false)
  }

  @Watch('settings', { deep: true })
  onSettingsChange (settings: PlotConfig) {
    if (this.ignoreNextUpdate) {
      this.ignoreNextUpdate = false
      return
    }
    console.log('settings update')
    this.update()

    if (settings.live) this.subscribe()
    else this.unsubscribe()
  }

  @Watch('tags')
  onTagsChange () {
    this.update()
  }
}
