




































import Vue from 'vue'
import BigBtn from '@/components/buttons/big-btn.vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    BigBtn
  }
})
export default class EConfirmDialog extends Vue {
  @Prop({ default: false }) value!: boolean;
  @Prop({ default: 'Ok' }) btnText!: string;
  @Prop({ default: '' }) icon!: string;
  @Prop({ default: 'error' }) color!: string;
  @Prop({ default: 'white' }) textColor!: string;

  title = ''
  cardText = ''
  dialog = false
  resolve!: (value: boolean) => void

  open (title: string, cardText: string) {
    this.dialog = true
    this.cardText = cardText
    this.title = title
    return new Promise(resolve => {
      this.resolve = resolve
    })
  }

  agree () {
    this.resolve(true)
    this.dialog = false
  }

  cancel () {
    this.resolve(false)
    this.dialog = false
  }
}
