


























































































import Vue from 'vue'
// import { mapState, mapActions } from "Vuex";
import { Component, Prop } from 'vue-property-decorator'
import eSelector from './e-selector.vue'
import eDateTimePicker from './e-datetimepicker.vue'
import TagPicker from './tag-picker/index.vue'
import moment from 'moment'
import * as _ from 'lodash'
import { PlotConfig } from './typedef'

interface QuickButton {
  name: string;
  icon: string;
  text: string;
  value: object;
}

@Component({
  components: { eSelector, TagPicker, eDateTimePicker }
})
export default class TagSidebar extends Vue {
  @Prop() value!: PlotConfig;
  @Prop() tags!: string[];
  @Prop({ default: 'Europe/Berlin' }) tz!: string;
  @Prop() screenUHD!: boolean;

  quickButtons: QuickButton[] = [
    {
      name: 'minutes',
      icon: 'access_time',
      text: '5 min.',
      value: { minutes: 5 }
    },
    { name: 'hour', icon: 'access_time', text: 'Time', value: { hour: 1 } },
    { name: 'day', icon: 'today', text: 'Dag', value: { day: 1 } },
    { name: 'week', icon: 'date_range', text: 'Uke', value: { week: 1 } },
    { name: 'month', icon: 'date_range', text: 'Måned', value: { month: 1 } },
    { name: 'year', icon: 'date_range', text: 'År', value: { year: 1 } }
  ];

  graphStyle = {
    label: 'Graf Stil',
    options: [
      { text: 'Linje', value: 'line' },
      { text: 'Stolpe', value: 'bar' }
    ]
  };

  maxDataPoints = 10000;

  toDateMenu = false;
  toTimeMenu = false;

  fromDateMenu = false;
  fromTimeMenu = false;

  manualUpdates = false;
  mini = false;

  beforeMount () {
    if (!this.value.to) {
      this.localValue = {
        resolution: 'auto',
        style: 'line',
        log: false,
        derived: false,
        live: true,
        from: moment.tz(this.tz).startOf('month'),
        to: moment.tz(this.tz)
      }
    }
  }

  quickDate (item: QuickButton) {
    const now = moment.tz(this.tz)

    this.$set(this.localValue, 'from', moment(now).subtract(item.value))
    this.$set(this.localValue, 'to', now)
  }

  updatePlot () {
    this.$emit('force-plot-update')
  }

  exportData () {
    console.log('data export')
  }

  toggleMini () {
    this.mini = !this.mini
  }

  get quickButtonLayout () {
    return _.map(_.chunk(this.quickButtons, 2), (row, num) => {
      return { num, row }
    })
  }

  get totalWidth () {
    if (this.screenUHD) return 575
    else return 350
  }

  get sideBarWidth () {
    return this.totalWidth + 'px'
  }

  get resolution (): object {
    return {
      label: 'Oppløsning',
      options: [
        { value: 'auto', text: this.autoResolution },
        { value: 'seconds', text: 'Sekunder' },
        { value: 'minutes', text: 'Minutter' },
        { value: 'hours', text: 'Timer' },
        { value: 'days', text: 'Dager' },
        { value: 'months', text: 'Måneder' }
      ]
    }
  }

  get autoResolution (): string {
    const secondDifference = moment(this.value.to).diff(
      moment(this.value.from),
      'seconds'
    )
    const dataPoints = this.tags.length * secondDifference
    if (dataPoints < this.maxDataPoints) return 'Auto (sekunder)'
    else if (dataPoints / 60 < this.maxDataPoints) return 'Auto (minutter)'
    else if (dataPoints / (60 * 60) < this.maxDataPoints) return 'Auto (timer)'
    else return 'Auto (dager)'
  }

  get localValue (): PlotConfig {
    return this.value
  }

  set localValue (localValue) {
    console.log('set localvalue')
    this.$emit('input', localValue)
  }

  get localTags (): string[] {
    return this.tags
  }

  set localTags (localTags) {
    this.$emit('update:tags', localTags)
  }
}
