/* global
ioSocket
*/
import { DateTime } from 'luxon'
import { ActionContext, Module } from 'vuex'
import { RootState } from '../../typedef'
import { MeterDataTemp } from '../eos_plot_store/typedef'
import { TableMathStoreState } from './typedef'

const namespaced = true

export const TableMathStore: Module<TableMathStoreState, RootState> = {
  namespaced,
  state: {
    to: DateTime.local().toISODate(),
    from: DateTime.local().startOf('year').toISODate(),
    res: 'days',
    loadingData: false,
    userInput1: 10,
    userInputUnit1: 'Kg'
  },
  mutations: {
    updateUserInput1 (state, input1) {
      state.userInput1 = input1
    },
    updateUserInputUnit1 (state, inputUnit1) {
      state.userInputUnit1 = inputUnit1
    },
    updateToDate (state, date) {
      state.to = date
    },
    updateFromDate (state, date) {
      state.from = date
    },
    updateLoadingData (state, isLoading) {
      state.loadingData = isLoading
    }
  },
  actions: {
    async fetchMeterData (
      action: ActionContext<TableMathStoreState, RootState>,
      uuids: string[]
    ): Promise<MeterDataTemp[]> {
      action.commit('updateLoadingData', true)
      const relations = await ioSocket.get(
        '/EosData/get',
        {
          from: action.state.from,
          to: action.state.to,
          meters: uuids,
          resolution: action.state.res
        }
      ) as MeterDataTemp[]
      // console.log('fetchMeterData relations', relations)
      action.commit('updateLoadingData', false)
      return relations
    }
  },
  getters: {
    getToDate: state => { return state.to },
    getFromDate: state => { return state.from },
    getIsLoading: state => { return state.loadingData },
    getUserInput1: state => { return state.userInput1 },
    getUserInputUnit1: state => { return state.userInputUnit1 }
  }
}
export default TableMathStore
