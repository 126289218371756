import { Resolution } from '@/store/modules/eos_plot_store/typedef'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { ApiEosDataPoint } from './eosDataQuery'

export class MapPoints {
  static dateFormat = 'yyyy-MM-dd';
  static mapPointsDays (points: ApiEosDataPoint[]): string[] {
    const year = Number(points[0].ts.slice(0, 4))
    const daysInYear = DateTime.utc().set({ year: year }).daysInYear
    const isLeapYear = daysInYear === 366
    const expectedPoints = _.times(daysInYear, i => {
      return DateTime.utc().set({ month: 1 }).set({ year: 2020, day: i + 1 })
        .toFormat(this.dateTiemFormatResolution(Resolution.days), {
          locale: 'nb'
        })
    })

    const expectedTs = _.times(daysInYear, i => {
      return DateTime.utc().set({ year: year, month: i + 1, day: 1 })
        .toFormat(this.dateFormat, {
          locale: 'nb'
        })
    })
    let x = _.map(points, (point) => {
      return DateTime.fromISO(point.ts)
        .toFormat(this.dateTiemFormatResolution(Resolution.days), {
          locale: 'nb'
        })
    })

    if (!isLeapYear && x[58] === '28. feb.') {
      x.splice(59, 0, '29. feb.')
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      points.splice(59, 0, { ts: `${year}-02-29` })
    }

    x = this.fillEmptyStartPoints(points, expectedPoints, expectedTs, x)
    return x
  }

  static mapPointsWeek (points: ApiEosDataPoint[]): string[] {
    let x = _.map(points, (point) => {
      return DateTime.fromISO(point.ts)
        .toFormat(this.dateTiemFormatResolution(Resolution.weeks), {
          locale: 'nb'
        })
    })

    const expectedPoints = _.times(52, i => {
      return DateTime.utc().set({ weekNumber: i + 1 })
        .toFormat(this.dateTiemFormatResolution(Resolution.weeks), {
          locale: 'nb'
        })
    })

    const year = Number(points[0].ts.slice(0, 4))

    const expectedTs = _.times(52, i => {
      return DateTime.utc().set({ year: year }).set({ weekNumber: i + 1 })
        .toFormat(this.dateFormat, {
          locale: 'nb'
        })
    })
    // ToDo: add value to first whole week???
    if (x[0] === '52' || x[0] === '53') {
      x.shift()
    }

    x = this.fillEmptyStartPoints(points, expectedPoints, expectedTs, x)

    // (temp?) If last monday counts as week 1 of next year
    // (https://no.wikipedia.org/wiki/Ukenummer)
    if (x[x.length - 1] === '01') {
      x.pop()
    }

    return x
  }

  static mapPointsMonth (points: ApiEosDataPoint[]): string[] {
    const expectedPoints = _.times(12, i => {
      return DateTime.utc().set({ month: i + 1, day: 1 }).toFormat('MMMM', {
        locale: 'nb'
      })
    })

    const year = Number(points[0].ts.slice(0, 4))

    const expectedTs = _.times(12, i => {
      return DateTime.utc().set({ year: year, month: i + 1, day: 1 })
        .toFormat(this.dateFormat, {
          locale: 'nb'
        })
    })
    let x = _.map(points, (point) => {
      return DateTime.fromISO(point.ts)
        .toFormat(this.dateTiemFormatResolution(Resolution.months), {
          locale: 'nb'
        })
    })

    x = this.fillEmptyStartPoints(points, expectedPoints, expectedTs, x)

    return x
  }

  static dateTiemFormatResolution (res: Resolution): string {
    switch (res) {
      case Resolution.hours: return ''
      case Resolution.days: return 'dd. MMM'
      case Resolution.weeks: return 'WW'
      case Resolution.months: return 'MMMM'
      default: return ''
    }
  }

  static fillEmptyStartPoints (
    points: ApiEosDataPoint[],
    expectedPoints: string[],
    expectedTs: string[],
    x: string[]) {
    _.forEach(expectedPoints, (ep, i) => {
      if (x[i] !== ep) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        points.splice(i, 0, { ts: expectedTs[i] })
        x.splice(i, 0, ep)
      } else {
        return false
      }
    })
    return x
  }
}
