/* global
ioSocket
*/
import state from '@/store'
import { Module, ActionContext } from 'vuex'
import { RootState } from '../../typedef'
import {
  IotUser,
  IotDevice,
  IotStoreState,
  CreateIotUser,
  EditIotUser,
  CreateIotDevice,
  EditIotDevice,
  ApiIotDevice,
  ApiCalResource,
  CreateCalResource
} from './typedef'

const namespaced = true

const IotStore: Module<IotStoreState, RootState> = {
  namespaced,
  state: {
    users: [],
    devices: []
  },
  mutations: {
    // USER
    addUsers (state, users: IotUser[]) {
      state.users.push(...users)
    },
    updateUsers (state, users: IotUser[]) {
      state.users = users
    },
    editUser (state, user: IotUser) {
      const internalUserIndex = state.users.findIndex(
        iUser => iUser.username === user.username
      )
      state.users.splice(internalUserIndex, 1, user)
    },
    removeUser (state, user: IotUser) {
      const internalUserIndex = state.users.findIndex(
        iUser => iUser.username === user.username
      )
      state.users.splice(internalUserIndex, 1)
    },

    // DEVICE
    addDevices (state, devices: IotDevice[]) {
      state.devices.push(...devices)
    },
    editDevice (state, device: IotDevice) {
      const internalDeviceIndex = state.devices.findIndex(
        iDevice => iDevice.name === device.name
      )
      state.devices.splice(internalDeviceIndex, 1, device)
    },
    removeDevice (state, device: IotDevice) {
      const internalDeviceIndex = state.devices.findIndex(
        iDevice => iDevice.name === device.name
      )
      state.devices.splice(internalDeviceIndex, 1)
    }
  },
  actions: {
    // USER
    async fetchIotUsers (
      action: ActionContext<IotStoreState, RootState>
    ): Promise<IotUser[]> {
      await ioSocket.get('/adminSettings/getIotUsers')
        .then(iotUsers => {
          // console.log('iotuser fetch', iotUsers)
          action.commit('updateUsers', iotUsers)
        })
      return action.state.users
    },
    async createIotUser (
      action: ActionContext<IotStoreState, RootState>,
      users: CreateIotUser
    ): Promise<IotUser[]> {
      console.log('createIotUser', users)
      const iotUsers = await ioSocket.post(
        '/adminSettings/createIoTUser', users).then(newUsers => {
        // console.log('createIotUser then', newUsers)
        action.commit('addUsers', newUsers)
        return newUsers as IotUser[]
      })
      return iotUsers
    },
    async editIotUser (
      action: ActionContext<IotStoreState, RootState>,
      user: EditIotUser
    ): Promise<IotUser> {
      console.log('editIotUser', user)
      const iotUser = await ioSocket.post(
        '/adminSettings/updateIoTUser', user).then(res => {
        // console.log('editIotUser then', res)
        // action.commit('editUser', iotUser)
        return res
      }).catch(e => {
        console.error(e)
      }) as IotUser
      return iotUser
    },
    async removeIotUser (
      action: ActionContext<IotStoreState, RootState>,
      user: IotUser
    ): Promise<number> {
      return await ioSocket.post(
        '/adminSettings/removeIoTUser', {
          username: user.username
        }).then(res => {
        // console.log('removeIotUser then', res)
        const countDeleted = res as number
        if (countDeleted > 0) {
          action.commit('removeUser', user)
        }
        return countDeleted
      }).catch(e => {
        console.error(e)
        return 0
      })
    },

    // DEVICE
    async fetchIotDevices (
      action: ActionContext<IotStoreState, RootState>
    ): Promise<ApiIotDevice[]> {
      const iotDevices = await ioSocket.get('/adminSettings/getDevices')
        .then(devices => {
          // console.log('iotDevices fetch', devices)
          action.commit('addDevices', devices)
          return devices
        }) as ApiIotDevice[]
      return iotDevices
    },
    async createIotDevice (
      action: ActionContext<IotStoreState, RootState>,
      device: CreateIotDevice
    ): Promise<IotDevice[]> {
      console.log('createIotDevice', device)
      const iotDevices = await ioSocket.post(
        '/adminSettings/createIotDevice', {
          name: device.name,
          siteUuid: device.siteUuid,
          iotUser: device.iotUser < 0 ? null : device.iotUser
        }).then(newDevices => {
        // console.log('createIotDevice then', newDevices)
        action.commit('addDevices', [newDevices])
        return newDevices as IotDevice[]
      })
      return iotDevices
    },
    async editIotDevice (
      action: ActionContext<IotStoreState, RootState>,
      device: EditIotDevice
    ): Promise<IotDevice> {
      const iotDevice = await ioSocket.post(
        '/adminSettings/updateDevice', {
          id: device.id,
          name: device.name,
          calendarId: device.calendarId,
          iotUserId: !device.iotUser || device.iotUser < 0
            ? null : device.iotUser
        }).then(editedDevices => {
        // console.log('editIotDevice then', editedDevices)
        // action.commit('addDevices', newDevices)

        return editedDevices as IotDevice[]
      })
      action.commit('editDevice', iotDevice[0])
      return iotDevice[0]
    },
    async removeIotDevice (
      action: ActionContext<IotStoreState, RootState>,
      device: IotDevice
    ): Promise<number> {
      const res = await ioSocket.post(
        '/adminSettings/removeIotDevice', { name: device.name }).then(count => {
        // console.log('removeIotDevice then', count)
        action.commit('removeDevice', device)
        return count as number
      })
      return res
    },
    // cal_resource
    async fetchResources (
      action: ActionContext<IotStoreState, RootState>,
      iotDeviceId: number | null
    ): Promise<ApiCalResource[]> {
      return await ioSocket.get('/adminSettings/getResources', { iotDeviceId })
        .then(resources => {
          // console.log('getResources fetch', resources)
          // action.commit('updateUsers', iotUsers)
          return resources
        }) as ApiCalResource[]
    },
    async createResourcesForSlots (
      action: ActionContext<IotStoreState, RootState>,
      createCalResource: CreateCalResource
    ): Promise<Partial<ApiCalResource>[]> {
      const relations = await ioSocket.post(
        '/adminSettings/createResourcesForSlots', {
          iotDeviceId: createCalResource.iotDeviceId,
          slotNumbers: createCalResource.slotNumbers
        }) as Partial<ApiCalResource>[]
      // action.commit('editResourceNameType', relations)
      return relations
    },
    async editResource (
      action: ActionContext<IotStoreState, RootState>,
      resource: Record<string, any>
    ): Promise<Record<string, any>> {
      const relations = await ioSocket.get(
        '/Calendar/editResource',
        {
          resource: resource
        }) as Record<string, any>
      // action.commit('editResourceNameType', relations)
      return relations
    },
    async deleteResource (
      action: ActionContext<IotStoreState, RootState>,
      resourceUuid: string
    ): Promise<number> {
      const count = await ioSocket.get(
        '/adminSettings/calDeleteResource',
        {
          uuid: resourceUuid
        }) as number
      return count
    }
  },
  getters: {
  }
}
export default IotStore
