import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'

import { RootState } from './typedef'

import { BaseStore } from './modules/base'
import { SiteStore } from './modules/site_store'
import { TagStore } from './modules/tag_store'
import { MeterStore } from './modules/meter_store'
import { UserStore } from './modules/user'
import { CalendarStore } from './modules/calendar_store'
import { EosPlotStore } from './modules/eos_plot_store'
import { ReportStore } from './modules/report_store'
import { MySitesStore } from './modules/my_sites'
import { TableMathStore } from './modules/table_math_store'
import { CO2eStore } from './modules/co2e_store'
import IotStore from '@/store/modules/iot_store'
import ElhubStore from '@/store/modules/elhub_store'
import EditMeterStore from '@/store/modules/edit_meter_store'

Vue.use(Vuex)

// false when building for production
const debug = process.env.NODE_ENV !== 'production'

export const store: StoreOptions<RootState> = {
  modules: {
    BaseStore,
    CalendarStore,
    EditMeterStore,
    ReportStore,
    MySitesStore,
    EosPlotStore,
    TableMathStore,
    ElhubStore,
    CO2eStore,
    IotStore,
    MeterStore,
    SiteStore,
    TagStore,
    UserStore
  },
  strict: debug
}

const state = new Vuex.Store<RootState>(store)
export default state
