import { Meter, Site } from '@/store/modules/site_store/typedef'
import { Tag } from '@/store/modules/tag_store/typedef'

export enum PlotNavOptions {
  consumption = 0,
  duration = 1,
  compare = 2,
  etcurve = 3
}
export interface LTNode {
  parent?: LTNode;
  text: string;
  depth: number;
  states: NodeState;
  data: NodeData;
  tree: object;
  vm: object;
}

export interface TreeNode {
  id?: number;
  // meterUuid?: string;
  // uuid?: string;
  text: string;
  data: NodeData;
  state: NodeState;
  states?: NodeState;
  children?: TreeNode[];
  vm?: object;
}

export interface NodeData {
  meter: Meter;
  uuid?: string;
  meterUuid?: string;
}

export interface NodeState {
  selected?: boolean;
  selectable?: boolean;
  checked?: boolean;
  expanded?: boolean;
  disabled?: boolean;
  visible?: boolean;
  indeterminate?: boolean;
  draggable?: boolean;
}

export const nodeStates: NodeState = {
  selected: false,
  selectable: true,
  checked: false,
  expanded: false,
  disabled: false,
  visible: true,
  indeterminate: false
}

export interface Trace {
  x: Array<string | number> | Array<string | number>[]; // dates
  y: Array<number>;
  name: string;
  uuid?: string; // ???
  year?: string;
  visible?: string;
  type: string;
  yaxis?: string;
  xaxis?: string;
  line: object;
  mode?: string;
  showlegend?: boolean;
  legendgroup?: string;
  opacity?: number;
  hovertemplate?: string;
  optimal?: boolean;
  text?: string | string[];
  textposition?: string;
  isEt?: boolean;
}

export interface TagListItem extends Tag {
    writableIcon: string;
    selected: boolean;
    value: number | 'N/A';
}

export interface FullSite extends Site{
    hierarchy: EosParentNode[];
}

export interface EosParentNode {
    comment: string;
    createdAt: string;
    displayName: string;
    expanded: boolean;
    readonly meterUuid: string;
    updatedAt: string;
    readonly uuid: string;
    children: EosParentNode[];
}

export interface TreeviewItem {
    id: string;
    name: string;
    children?: (TreeviewItem | undefined)[];
    leaf: boolean;
    type?: number;
    unit: string;
    locked: boolean;
}

export interface HierarchyMeter {
    area: number | null;
    createdAt: string | null;
    displayName: string;
    main: boolean;
    manual_values: boolean;
    name: string | null;
    producer: boolean;
    type: number;
    unit: string | null;
    updatedAt: string | null;
    uuid: string;
}

export type EnergyUnits = 'kWh' | 'L' | 'm³' | ''

export const dispTextSiteMeterPicker = {
  nb: {
    hours: 'Timer',
    days: 'Dager',
    weeks: 'Uker',
    months: 'Måneder',
    years: 'År',
    chooseMeters: 'Velg målere',
    chooseAll: 'Velg alle',
    removeChosen: 'Fjern valg',
    numberOfChosen: 'Antall målere valgt: ',
    maximum: 'Maks ',
    numberOfMeters: ' valgte målere samtidig',
    searchSite: 'Søk på bygg...',
    expandAll: 'Utvid alle',
    collapseAll: 'Kollaps alle',
    chosen: 'Valg',
    name: 'Navn',
    energyType: 'Energitype',
    energyTypes: {
      0: 'Elektrisk',
      1: 'Vann',
      2: 'Olje',
      3: 'Diesel'
    },
    unitTitle: {
      kWh: 'Energi',
      L: 'Liter',
      'm\u00B3': 'Kubikk',
      default: ''
    },
    noData: 'Ingen data tilgjengelig...',
    noSite: 'Ingen bygg valgt...',
    chooseOneMeter: 'Vennligst velg minst 1 måler',
    noMoreThan: 'Du kan ikke laste data fra mer enn ',
    metersAtOnce: ' målere om gangen',
    electrical: 'Elektrisk',
    water: 'Vann',
    oil: 'Olje',
    diesel: 'Diesel',
    searchFilter: 'Søkefilter',
    view: 'Visning',
    group: 'Gruppe',
    list: 'Liste',
    settings: 'Innstillinger',
    noFilteredData: 'Ingen data for følgende innstillinger...',
    groupEdit: 'Rediger grupper',
    off: 'Av',
    on: 'På',
    addNewGroup: 'Ny gruppe',
    removeChosenGroup: 'Fjern gruppe',
    unableToMoveGroup: 'Kunne ikke flyttet gruppe, vennligst prøv igjen senere',
    unableToRemoveGroup:
    'Kunne ikke fjerne gruppe, vennligst prøv igjen senere',
    unableToAddGroup: 'Kunne ikke legge til gruppe, vennligst prøv igjen senere'
  },
  en: {
    hours: 'Hours',
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
    years: 'Years',
    chooseMeters: 'Choose meters',
    chooseAll: 'Choose all',
    removeChosen: 'Remove chosen',
    numberOfChosen: 'Number of chosen meters: ',
    maximum: 'Max ',
    numberOfMeters: ' chosen meters at once',
    searchSite: 'Search site...',
    expandAll: 'Expand all',
    collapseAll: 'Collapse all',
    chosen: 'Chosen',
    name: 'Name',
    energyType: 'Energy type',
    energyTypes: {
      0: 'Electric',
      1: 'Water',
      2: 'Oil',
      3: 'Diesel'
    },
    unitTitle: {
      kWh: 'Energy',
      L: 'Liter',
      'm\u00B3': 'Cubic',
      default: ''
    },
    noData: 'No data available...',
    noSite: 'No site chosen...',
    chooseOneMeter: 'Please choose at least 1 meter',
    noMoreThan: 'You can not load data from more than ',
    metersAtOnce: ' meters at once',
    electrical: 'Electrical',
    water: 'Water',
    oil: 'Oil',
    diesel: 'Diesel',
    searchFilter: 'Search filter',
    view: 'View',
    group: 'Group',
    list: 'List',
    settings: 'Settings',
    noFilteredData: 'No data for current settings...',
    groupEdit: 'Group edit',
    off: 'Off',
    on: 'On',
    addNewGroup: 'Add group',
    removeChosenGroup: 'Remove group',
    unableToMoveGroup: 'Unable to move group, please try again later',
    unableToRemoveGroup: 'Unable to remove group, please try again later',
    unableToAddGroup: 'Unable to add group, please try again later'
  }

}
