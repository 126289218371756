var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":false,"persistent":"","fullscreen":_vm.fullscreen,"max-width":"70vw","scrollable":true},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{attrs:{"max-height":"90vh","tile":"","loading":_vm.meterDataLoading}},[_c('v-toolbar',{attrs:{"max-height":"56px","flat":"","dense":"","height":"56px","dark":"","color":"primary"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.dispText.chooseMeters))])],1),_c('v-divider'),_c('v-card',{attrs:{"height":"120px","outlined":"","tile":""}},[_c('v-card-text',{staticClass:"pt-1 mt-5 mb-0 pb-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pb-n1 mb-n1",attrs:{"cols":"8"}},[_c('site-search',{attrs:{"multiple":false,"clearOnSelect":true},model:{value:(_vm.selectedSite),callback:function ($$v) {_vm.selectedSite=$$v},expression:"selectedSite"}})],1),_c('v-col',{staticClass:"text-sm-right text-sx-left",attrs:{"cols":"4","xs12":""}},[_c('div',{class:{ 'pt-2': _vm.$vuetify.breakpoint.smAndUp }},[_c('strong',[_vm._v(" "+_vm._s(_vm.dispText.numberOfChosen)+" "+_vm._s(_vm.localSelected.length)+" ")])]),_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.dispText.maximum)+" "+_vm._s(_vm.maxSelectedMeter)+" "+_vm._s(_vm.dispText.numberOfMeters)+" ")])]),_c('v-col',{staticClass:"pt-0 mt-n1",attrs:{"align-self":"start","cols":"8"}},[_c('v-container',{staticClass:"d-flex py-0 px-0 mx-0"},[_c('v-btn',{staticClass:"mt-2 mb-0 pb-0 mx-0 px-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.selectAll
                        (null, _vm.isAnySomeAllSelected === -1)}}},[_c('v-icon',{attrs:{"color":"primary"},domProps:{"innerHTML":_vm._s(_vm.isAnySomeAllSelected === -1 ?
                          'mdi-checkbox-multiple-blank-outline' :
                          (_vm.isAnySomeAllSelected === 0 ?
                          '$checkboxIndeterminate' :
                            'mdi-checkbox-multiple-marked'))}})],1),_c('v-chip-group',{key:_vm.localSelected.count,staticClass:"py-0 px-1",attrs:{"mandatory":"","column":"","active-class":"primary--text"}},_vm._l((_vm.visitedSitesWCount),function(s,i){return _c('v-chip',{key:i,attrs:{"label":"","outlined":"","close":""},on:{"click:close":function($event){return _vm.unselectAllOnSite(s.siteUuid)},"click":function($event){_vm.selectedSite = s.siteUuid}}},[_vm._v(" "+_vm._s(s.displayName)+" "),(s.count > 0)?_c('v-chip',{staticClass:"ml-2 mr-0",attrs:{"small":"","outlined":""}},[_c('strong',[_vm._v(_vm._s(s.count))])]):_vm._e()],1)}),1)],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"align-self":"start","cols":"4"}},[_c('v-container',{staticClass:"d-flex justify-end pt-2 px-1"},[_c('v-btn',{staticClass:"mr-1",attrs:{"color":"primary","depressed":"","small":""},on:{"click":_vm.expandAll}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-chevron-down ")]),_vm._v(" "+_vm._s(_vm.dispText.expandAll)+" ")],1),_c('v-btn',{attrs:{"color":"primary","depressed":"","small":""},on:{"click":_vm.collapseAll}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-chevron-right ")]),_vm._v(" "+_vm._s(_vm.dispText.collapseAll)+" ")],1)],1)],1)],1)],1)],1),_c('v-container',{staticClass:"mt-0 pt-0 mb-3 pb-4",attrs:{"fluid":""}},[_c('v-responsive',{staticClass:"overflow-y-auto ",staticStyle:{"top":"0px"},attrs:{"max-height":_vm.fullscreen ? '60vh' : '55vh',"min-height":"55vh"}},[_c('v-card',{attrs:{"loading":_vm.loadingTree,"tile":"","elevation":"0"}},[_c('liquor-tree',{key:_vm.treeKey,ref:"tree",attrs:{"filter":_vm.treeQuery,"data":_vm.treeData,"options":_vm.treeOptions},on:{"node:dragging:finish":_vm.moveNode,"node:text:changed":_vm.saveNodeEdit,"node:selected":_vm.selectNode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var node = ref.node;
return _c('v-container',{staticClass:"ml-0 pl-0 py-0 ma-0",attrs:{"fluid":""}},[(_vm.setVisibleDraggable(node))?[_c('v-container',{staticClass:"d-flex flex-row  pa-0 ma-0",attrs:{"fluid":""}},[(_vm.isNodeAMeter(node))?_c('v-simple-checkbox',{attrs:{"color":"primary","ripple":false},on:{"input":function($event){return _vm.checkMeter(node.states.checked, node.data.meter)}},model:{value:(node.states.checked),callback:function ($$v) {_vm.$set(node.states, "checked", $$v)},expression:"node.states.checked"}}):_vm._e(),(node.children.length > 0)?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"grey-darken2"},domProps:{"innerHTML":_vm._s(_vm.isAnySomeAllCSelected(node) === -1 ?
                          'mdi-checkbox-multiple-blank-outline' :
                          (_vm.isAnySomeAllCSelected(node) === 0 ?
                          '$checkboxIndeterminate' :
                            'mdi-checkbox-multiple-marked'))},on:{"click":function($event){$event.stopPropagation();_vm.selectChildren(node,
                        _vm.isAnySomeAllCSelected(node) === -1)}}}):_vm._e(),_c('v-icon',{staticClass:"mr-1",domProps:{"textContent":_vm._s(_vm.getNodeIcon(node.data.meter))}}),_vm._v(" "+_vm._s(node.text)+" "),(_vm.isNodeAMeter(node))?_c('span',{staticClass:"px-2 text-caption\n                      font-weight-medium text--secondary"},[_vm._v(" "+_vm._s(_vm.dispText[_vm.meterType[node.data.meter.type]])+" ["+_vm._s(node.data.meter.unit)+"] ")]):_vm._e(),(!_vm.isNodeAMeter(node) && _vm.editMode)?_c('span',{staticClass:"px-2"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return node.startEditing()}}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-square-edit-outline")])],1),_c('v-btn',{attrs:{"icon":"","disabled":node.children.length > 0,"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.promptDeleteNode(node)}}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("delete_forever")])],1)],1):_vm._e(),(_vm.isNodeAMeter(node) && _vm.useFavorites)?_c('div',{staticClass:"ml-auto pl-auto mr-0 pr-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleFavorite(node)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s(
                                _vm.isFavoriteMeter(node)
                                  ? 'mdi-star'
                                  : 'mdi-star-outline'
                              )}})],1)]}}],null,true)},[(_vm.isFavoriteMeter(node))?_c('span',[_vm._v(" Fjern fra favoritter ")]):_c('span',[_vm._v("Legg til som favoritt")])])],1):_vm._e()],1)]:_vm._e()],2)}}]),model:{value:(_vm.treeModel),callback:function ($$v) {_vm.treeModel=$$v},expression:"treeModel"}})],1)],1)],1),_c('v-col',{attrs:{"cols":'auto'}},[_c('meter-selector-menu',{attrs:{"settingsDrawer":_vm.settingsDrawer,"editMode":_vm.editMode,"getSelectedSiteUuid":_vm.getSelectedSiteUuid,"updateSelectedEnergyTypes":_vm.updateSelectedEnergyTypes,"updateTreeView":_vm.updateTreeView},on:{"update:settingsDrawer":function($event){_vm.settingsDrawer=$event},"update:settings-drawer":function($event){_vm.settingsDrawer=$event},"update:editMode":function($event){_vm.editMode=$event},"update:edit-mode":function($event){_vm.editMode=$event},"updateEditMode":_vm.updateEditMode},scopedSlots:_vm._u([{key:"treeFilter",fn:function(){return [_c('v-text-field',{attrs:{"value":_vm.search,"prepend-icon":"search","placeholder":"Søk"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}})],1),_c('v-footer',{staticClass:"mt-5",attrs:{"absolute":"","width":"auto","padless":""}},[_c('v-card-actions',{staticClass:"ml-auto pl-auto"},[_c('big-btn',{attrs:{"buttonName":"Lukk","icon":"cancel","outlined":true},on:{"click":function($event){_vm.open = false}}}),_c('big-btn',{attrs:{"buttonName":"Bruk","icon":"check","outlined":false},on:{"click":function($event){return _vm.useSelected()}}})],1)],1),_c('v-snackbar',{attrs:{"color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Lukk ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('e-confirm-dialog',{ref:"confirm",attrs:{"icon":"delete_forever","btnText":"Slett"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }