import moment, { Moment } from 'moment-timezone'
import { CalendarEvent, VCalendarEvent } from './typedef'
import _ from 'lodash'
import { ApiEvent } from '../../../pages/ecalendar/typedef'

export default class BasicEvent implements CalendarEvent {
  constructor (
    public name: string,
    public comment: string,
    public start: Moment,
    public end: Moment,
    public exception: boolean,
    public inactive: boolean,
    readonly uuid: string,
    public resource: string[],
    public group: string[],
    public tz: string,
     public updatedAt?: Moment,
    public createdAt?: Moment,
    public createdBy?: string
  ) { }

  /**
   * For repeat event, returns all instances between from and to,
   * for singular event returns event if between from and to
   */
  eventsWithin (from: Moment, to: Moment): VCalendarEvent[] {
    const isWithin = this.start.isBefore(to) && this.end.isAfter(from)

    return isWithin
      ? [
        this.generateVCalendarEvent(
          this.name,
          this.start,
          this.end,
          this.tz
        )
      ]
      : []
  }

  get color (): string {
    return this.exception ? 'accent' : 'primary'
  }

  protected generateVCalendarEvent (
    name: string,
    start: Moment,
    end: Moment,
    tz: string
  ): VCalendarEvent {
    return {
      name,
      start: moment.tz(start, tz).format(BasicEvent.vCalendarFormat),
      end: moment.tz(end, tz).format(BasicEvent.vCalendarFormat),
      baseEvent: this
    }
  }

  copy (): BasicEvent {
    return new BasicEvent(
      this.name,
      this.comment,
      moment(this.start),
      moment(this.end),
      this.exception,
      this.inactive,
      this.uuid,
      _.clone(this.resource),
      _.clone(this.group),
      this.tz,
      this.updatedAt,
      this.createdAt,
      this.createdBy
    )
  }

  apiExport (): ApiEvent {
    return {
      title: this.name,
      from: this.start.toISOString(),
      to: this.end.toISOString(),
      exception: this.exception,
      inactive: this.inactive,
      comment: this.comment,
      uuid: this.uuid,
      resource: this.resource,
      group: this.group
    }
  }

  private static vCalendarFormat = 'YYYY-MM-DD HH:mm';
}
