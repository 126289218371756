import {
  EosParentNode,
  PlotNavOptions,
  TreeNode
} from '@/components/eos-plot/typedef'
import { Meter, Site } from '../site_store/typedef'

export interface EosPlotStoreState {
  selectedSiteUuid: string;
  selectedMeters: SiteMeter[];
  selectedSite?: Site;
  treeData: TreeNode[];
  hierarchy: EosParentNode[];
  meters: Meter[];
  selectedEnergyTypes: string[];
  search: string;
  treeView: boolean;
  selectedDataRaw: MeterDataTemp[];
  editMode: boolean;
  plotConfigs: EosPlotConfig[];
  plotConfig: EosPlotConfig;
  plotNav: PlotNavOptions;
  selectedYears: string[];
  selectedYearET: string;
  availableYears: string[];
  drawETEnabled: boolean;
  newETPoints: XYPoint[];
  etCurves: ETCurveNl[];
}

export interface ApiEtCurve {
  meterUuid: string;
  etCurves: ETCurve[];
}

export interface ApiEtCurveNl {
  meterUuid: string;
  etCurves: ETCurveNl[];
}

export interface XYPoint {
  x: number;
  y: number;
}

export interface ETPoint {
  x: number;
  y: number;
  id: number;
  createdAt?: string;
}

export interface ETCurve {
  bottom: XYPoint;
  top: XYPoint;
  createdAt: string;
  createdBy: string;
  resolution: string;
  updatedAt: string;
  year: number;
}

export interface ETCurveNl {
  points: ETPoint[];
  createdAt: string;
  createdBy: string;
  resolution: string;
  updatedAt: string;
  year: number;
  id: number;
  comment?: string;
  color?: string;
}

export interface EosPlotConfig {
  // type: string;
  to: string;
  from: string;
  resolution: Resolution;
  cumulative: boolean;
  stacked: boolean;
  prArea: boolean;
  graphStyle: GraphStyle;
  selectedMeterUuid: string;
}

export interface MeterDataTemp {
  uuid: string;
  values: PointTemp[];
}

export interface PointTemp {
  ts: string;
  value: number;
  temperature?: number;
}
export interface SiteMeter {
  siteUuid: string;
  meter: Meter;
}

export interface EosDataQueryOptions {
  to: string;
  from: string;
  uuids?: string[];
  res: Resolution;
}

export type GraphStyle = 'line' | 'bar'

/* export type Resolution =
    'hours' | 'days' | 'weeks' | 'months' | 'years';
 */
export enum Resolution {
  hours = 'hours',
  days = 'days',
  weeks = 'weeks',
  months = 'months',
  years = 'years'
}
