


























































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import moment, { Moment } from 'moment'

@Component
export default class EDateTimePicker extends Vue {
  @Prop() value!: Moment;
  @Prop() min!: Moment;
  @Prop() max!: Moment;
  @Prop({ default: 'Europe/Berlin' }) tz!: string;
  @Prop({ default: 'Velg fra dato' }) label!: string;
  @Prop({ default: 'Klokkeslett' }) timeLabel!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: true }) includeTime!: boolean;
  @Prop({ default: true }) includeDate!: boolean;

  dateMenu = false;
  timeMenu = false;

  get date () {
    return moment(this.value).format('YYYY-MM-DD')
  }

  set date (date) {
    this.localValue = moment.tz(date + ' ' + this.time, this.tz)
  }

  get time () {
    return moment(this.value).format('HH:mm')
  }

  set time (time) {
    this.localValue = moment.tz(this.date + ' ' + time, this.tz)
  }

  get maxDate () {
    if (this.max) return this.max.format('YYYY-MM-DD')
    else return null
  }

  get minDate () {
    if (this.min) return this.min.format('YYYY-MM-DD')
    else return null
  }

  get minHour () {
    if (this.min && this.min.isSame(this.value, 'day')) {
      return moment(this.min)
        .add(1, 'm')
        .format('HH:mm')
    } else return null
  }

  get maxHour () {
    if (this.max && this.max.isSame(this.value, 'day')) {
      return moment(this.max)
        .subtract(1, 'm')
        .format('HH:mm')
    } else return null
  }

  get localValue () {
    return this.value
  }

  set localValue (localValue) {
    this.$emit('input', localValue)
  }
}
