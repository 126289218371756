








































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { mapState, mapMutations } from 'vuex'
import store from '../store/'
import { Site } from '../store/modules/site_store/typedef'
import SiteSearch from '@/components/site-search/index.vue'

@Component({
  components: {
    SiteSearch
  },
  store,
  computed: {
    ...mapState('BaseStore', { siteUuid: 'currentSiteUuid' }),
    ...mapState('BaseStore', { theme: 'theme' }),
    ...mapState('SiteStore', { siteMap: 'siteMap' })

  },
  methods: mapMutations('BaseStore', { changeSite: 'changeSite' })
})
export default class EToolbar extends Vue {
  @Prop() page!: string;
  @Prop() screenUHD!: boolean;
  @Prop() sideNavOpen!: boolean;
  @Prop({ default: false }) siteless!: boolean;

  siteUuid!: string;
  siteMap!: {[uuid: string]: Site};
  theme!: string;
  siteSearchDialog = false;

  selectedSite = '';

  changeSite!: (selectedSite: string) => never

  get site (): Site {
    return this.siteMap[this.siteUuid]
  }

  get title (): string {
    if (this.siteless || !this.site) return `${this.page}`
    else return `${this.page} | ${this.site.displayName}`
  }

  get toolBarHeight (): string | undefined {
    let result
    if (this.screenUHD) {
      result = '100px'
    }
    return result
  }

  get logoPath () {
    return `/images/logos/${this.theme}/wpadding.svg`
  }

  back () {
    return window.history.back()
  }

  openNav () {
    this.localSideNavOpen = true
  }

  @Watch('selectedSite')
  async onSiteChange (selectedSite: string) {
    // close dialog
    this.siteSearchDialog = false
    // change currentSite

    this.changeSite(selectedSite)
  }

  get localSideNavOpen () {
    return this.sideNavOpen
  }

  set localSideNavOpen (open: boolean) {
    this.$emit('update:sideNavOpen', open)
  }
}
