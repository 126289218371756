/* global
ioSocket
*/
import { ApiEventToCalendarEvent, CalendarEvent } from '@/components/ecalendar/event'
import { RootState } from '@/store/typedef'
import { ActionContext, Module } from 'vuex'
import { CalendarStoreState, GetCalendarReturn, NewGroup } from './typedef'
import * as _ from 'lodash'
import { ApiGroup, ApiResource } from '@/pages/ecalendar/typedef'
import { User } from '../user'

const namespaced = true
const state: CalendarStoreState = {
  calendarReturn: {
    resources: [],
    groups: [],
    events: []
  },
  baseEvents: [],
  viewMode: 'events',
  intervalUnit: 'week'
}

export const CalendarStore: Module<CalendarStoreState, RootState> = {
  namespaced,
  state,
  getters: {
    calendarReturn: (state) => {
      return state.calendarReturn
    },
    baseEvents: (state) => {
      return state.baseEvents
    }
  },
  mutations: {
    addCalendarReturn (state, calendarReturn: GetCalendarReturn) {
      calendarReturn.resources = _.sortBy(calendarReturn.resources, ['type'])
      state.calendarReturn = calendarReturn

      if (state.viewMode === 'events') {
        state.baseEvents = calendarReturn.events.map((event) => {
          return ApiEventToCalendarEvent(event, 'Europe/Berlin')
        })
      } else if (state.viewMode === 'resources') {
        state.baseEvents = []
        calendarReturn.events.map((event) => {
          const resEvent = ApiEventToCalendarEvent(event, 'Europe/Berlin')
          // add each resource as copied event, resource-displayname as altName
          event.resource.map((r) => {
            resEvent.altName = _.find(calendarReturn.resources, ['uuid', r])?.displayName || ''
            state.baseEvents.push(_.clone(resEvent))
          })
          // same for each resource in each group
          event.group.map((g) => {
            const group = _.find(calendarReturn.groups, ['uuid', g])
            // eslint-disable-next-line no-unused-expressions
            group?.members.map((gr) => {
              resEvent.altName = _.find(calendarReturn.resources, ['uuid', gr])?.displayName || ''
              state.baseEvents.push(_.clone(resEvent))
            })
          })
        })
      }
    },
    removeCalendarEvent (state, eventUuids: string[]) {
      state.baseEvents = _.clone(
        _.pullAllBy(state.baseEvents, [{ uuid: eventUuids[0] }], 'uuid'))
    },
    editResourceNameType (state, rel: ApiResource[]) {
      const i = state.calendarReturn.resources.findIndex(
        e => e.uuid === rel[0].uuid)
      state.calendarReturn.resources[i].displayName = rel[0].displayName
      state.calendarReturn.resources[i].type = rel[0].type
    },
    addGroup (state, newGroup) {
      const newApiGroup: ApiGroup = {
        displayName: newGroup.displayName,
        uuid: newGroup.uuid,
        comment: '',
        members: newGroup.resources,
        type: 0 // ??
      }
      state.calendarReturn.groups.push(newApiGroup)
    },
    editGroup (state, group) {
      state.calendarReturn.groups.map(g => {
        if (g.uuid === group.uuid) {
          g.members = group.resources
          g.comment = group.comment
        }
      })
    },
    deleteGroup (state, group) {
      state.calendarReturn.groups = _.clone(
        _.pullAllBy(state.calendarReturn.groups, [{ uuid: group[0] }], 'uuid'))
    },
    setViewMode (state, viewMode) {
      state.viewMode = viewMode
    },
    setIntervalUnit (state, unit) {
      state.intervalUnit = unit
    },
    setCreatedBy (state, { user, event }) {
      event.createdBy = user.displayName
    }
  },
  actions: {
    async fetchEvents (
      action: ActionContext<CalendarStoreState, RootState>,
      siteUuid: string
    ): Promise<GetCalendarReturn> {
      const relations = await ioSocket.get(
        '/Calendar/getCalendar',
        {
          siteUuid: siteUuid,
          expired: true,
          inactive: false
        }) as GetCalendarReturn
      action.commit('addCalendarReturn', relations)
      return relations
    },
    async fetchUser (
      action: ActionContext<CalendarStoreState, RootState>,
      event: CalendarEvent
    ) {
      const relations = await ioSocket.get(
        '/User/get',
        {
          id: Number(event.createdBy)
        }) as User[]
      action.commit('setCreatedBy', { user: relations[0], event: event })
      return relations
    },
    async deleteCalendarEvent (
      action: ActionContext<CalendarStoreState, RootState>,
      eventUuids: string[]
    ): Promise<Record<string, any>> {
      const relations = await ioSocket.get(
        '/Calendar/deleteEvent',
        {
          eventUuids: eventUuids
        }) as Record<string, any>
      action.commit('removeCalendarEvent', eventUuids)
      return relations
    },
    async editResource (
      action: ActionContext<CalendarStoreState, RootState>,
      resource: Record<string, any>
    ): Promise<Record<string, any>> {
      const relations = await ioSocket.get(
        '/Calendar/editResource',
        {
          resource: resource
        }) as Record<string, any>
      action.commit('editResourceNameType', relations)
      return relations
    },
    async addGroup (
      action: ActionContext<CalendarStoreState, RootState>,
      newGroup: NewGroup
    ): Promise<Record<string, any>> {
      const relations = await ioSocket.get(
        '/Calendar/addGroup',
        {
          groups: [{
            displayName: newGroup.displayName,
            siteUuid: newGroup.siteUuid,
            resources: []
          }] as Record<string, any>[]
        }) as Record<string, any>[]
      action.commit('addGroup', relations[0])
      return relations
    },
    async editGroup (
      action: ActionContext<CalendarStoreState, RootState>,
      editedGroup: ApiGroup
    ): Promise<Record<string, any>> {
      const relations = await ioSocket.get(
        '/Calendar/editGroup',
        {
          groups: [{
            uuid: editedGroup.uuid,
            resources: editedGroup.members,
            comment: editedGroup.comment
          }]
        }) as Record<string, any>[]
      action.commit('editGroup', relations[0])
      return relations
    },
    async deleteGroup (
      action: ActionContext<CalendarStoreState, RootState>,
      groupUuid: string
    ): Promise<Record<string, any>> {
      const relations = await ioSocket.get(
        '/Calendar/deleteGroup',
        {
          groups: groupUuid
        }) as ApiGroup[]
      action.commit('deleteGroup', relations)
      return relations
    }
  }
}
export default CalendarStore
