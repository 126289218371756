











import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Layout, PlotData } from 'plotly.js'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Plotly from 'plotly.js-dist'
import EOverlay from '@/components/e-overlay.vue'

import uuid from '../../lib/uuid'
import { Trace } from './typedef'

interface PlotConfig {
  layout: Layout;
  data: Trace[];
}

@Component({
  components: { EOverlay }
})
export default class EosPlotly extends Vue {
  @Prop() traces!: Trace[];
  @Prop() layout!: Layout;
  @Prop({ default: true }) useOverlay!: boolean;
  @Prop({ default: false }) static!: boolean;
  @Prop() newPlot!: boolean;
  @Prop({ default: false }) pause!: boolean;
  id: string = uuid();
  emptyPlot = true;
  rendering = false

  get overlayText (): string | null {
    return this.pause ? null : 'Klargjør Data'
  }

  mounted () {
    // console.log('mounted eos-plotly')
    this.onPlotConfigChanged()
  }

  @Watch('layout', { deep: true, immediate: false })
  async watchLayout () {
    console.log('watchLayout')
    // this.watchConfig()
  }

  @Watch('traces', { deep: true, immediate: false })
  watchTraces () {
    console.log('watchTraces', this.traces)
    // this.watchConfig()
  }

  get plotConfig (): PlotConfig {
    return { data: this.traces, layout: this.layout }
  }

  @Watch('plotConfig', { deep: true, immediate: false })
  async watchConfig () {
    this.rendering = true
    _.defer(() => {
      this.onPlotConfigChanged()
      // this.$emit('trackPlotConfig')
    })
    // console.log('watchConfig')
  }

  async onPlotConfigChanged () {
    // console.log('onPlotConfigChanged, paused:', this.pause)
    if (this.pause) return
    this.rendering = true

    if (this.emptyPlot) {
      this.emptyPlot = false

      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      Plotly.register({
        moduleType: 'locale',
        name: 'no',
        dictionary: {},
        format: {
          days: [
            'S\xf8ndag', 'Mandag', 'Tirsdag', 'Onsdag',
            'Torsdag', 'Fredag', 'L\xf8rdag'
          ],
          shortDays: ['S\xf8n', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'L\xf8r'],
          months: [
            'Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni',
            'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'
          ],
          shortMonths: [
            'Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul',
            'Aug', 'Sep', 'Okt', 'Nov', 'Des'],
          date: '%d.%m.%Y'
        }
      })
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      Plotly.setPlotConfig({ locale: 'no' })
      console.log('newPlot')
      Plotly.newPlot(
        this.id,
        this.traces as PlotData[],
        this.layout,
        {
          displaylogo: false,
          responsive: true,
          modeBarButtonsToRemove: ['select2d', 'lasso2d'],
          displayModeBar: this.static ? false : 'hover',
          staticPlot: this.static
        }
      )

      this.rendering = false
    } else {
      if (!this.static) {
        Plotly.d3.select('.plotly').on(
          'click', () => this.handleClick())
      }
      console.log('react', this.traces)
      Plotly.react(this.id, this.traces, this.layout)
      this.rendering = false
    }
  }

  handleClick () {
    const chartDiv = document.getElementById(this.id) as any
    const e = Plotly.d3.event
    if (chartDiv) {
      const chartOffset = chartDiv.getBoundingClientRect()
      const clickXCoordinate = e.clientX - chartOffset.left
      const clickYCoordinate = e.clientY - chartOffset.top
      const xMin = chartDiv.layout.xaxis.range[0]
      const xMax = chartDiv.layout.xaxis.range[1]
      const yMin = chartDiv.layout.yaxis.range[0]
      const yMax = chartDiv.layout.yaxis.range[1]

      const xaxis = chartDiv._fullLayout.xaxis
      const yaxis = chartDiv._fullLayout.yaxis
      const l = chartDiv._fullLayout.margin.l
      const t = chartDiv._fullLayout.margin.t

      // added offset because plotly draws from top
      // left(?) instead of center
      const x = xaxis.p2c(clickXCoordinate - l + 2)
      const y = yaxis.p2c(clickYCoordinate - t + 1)
      // console.log('plotly_click', x, y)
      // abort if click outside axis
      if (!(x >= xMin && x <= xMax && y >= yMin && y <= yMax)) {
        return
      }
      this.$emit('plotlyClick', x, y)
    }
  }
}
