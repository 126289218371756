





















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class EOverlay extends Vue {
  @Prop({ required: true }) value!: boolean
  @Prop({ default: 0.2 }) opacity!: number;
  @Prop({ default: true }) absolute!: boolean
  @Prop({ default: 'primary' }) color!: string
  @Prop({ default: null }) text!: string | null
}
