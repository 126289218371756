/* global
ioSocket
*/
import _ from 'lodash'
import humanizeDuration from 'humanize-duration'
import { DateTime } from 'luxon'
import { Module, ActionContext } from 'vuex'
import { RootState } from '../../typedef'
import {
  MeterSum,
  MySitesStoreState
} from './typedef'
import { MeterDataTemp } from '../eos_plot_store/typedef'

const namespaced = true

export const MySitesStore: Module<MySitesStoreState, RootState> = {
  namespaced,
  state: {
    siteTotal: {},
    resolution: 'days',
    fromDate: DateTime.local().minus({ days: 30 }).toISODate(), // DateTime.local().startOf('year').toISODate(),
    toDate: DateTime.local().toISODate()
  },
  mutations: {
    updateSiteTotal (state, siteTotal) {
      state.siteTotal[siteTotal.uuid] = siteTotal.value
    },
    updateFromDate (state, date) {
      state.fromDate = date
    },
    updateToDate (state, date) {
      state.toDate = date
    }
  },
  actions: {
    async fetchMeterSum (
      action: ActionContext<MySitesStoreState, RootState>,
      uuids: string[]
    ): Promise<number> {
      // console.log('fetchMeterSum uuids', uuids)
      const sum = await ioSocket
        .get('/EosData/getSum', {
          meters: uuids,
          resolution: action.state.resolution,
          from: action.state.fromDate,
          to: action.state.toDate
        }).then(result => {
          // console.log('fetchMeterSum result', result)
          return _.round(_.sumBy(result as MeterSum[], r => {
            return r.value.value
          }), 2)
        })
      // console.log('fetchMeterSum', sum)
      return sum
    },
    async fetchMeterData (
      action: ActionContext<MySitesStoreState, RootState>,
      uuids: string[]
    ): Promise<MeterDataTemp[]> {
      const sum = await ioSocket
        .get('/EosData/get', {
          meters: uuids,
          resolution: 'days',
          from: action.state.fromDate,
          to: action.state.toDate
        }).then(result => {
          // console.log('fetchMeterData result', result)
          return result as MeterDataTemp[]
        })
      console.log('fetchMeterData', sum)
      return sum
    },
    async fetchMeterLast (
      action: ActionContext<MySitesStoreState, RootState>,
      uuids: string[]
    ): Promise<string | undefined> {
      const lastTs = await ioSocket
        .get('/EosData/getLastValue', {
          meters: uuids,
          resolution: 'hours'
        }).then(result => {
          return _.maxBy(result as MeterSum[], r => {
            return r.value.ts
          })?.value.ts
        })
      /* if (lastTs) {
        lastTs = humanizeDuration(DateTime.fromISO(lastTs)
          .diffNow().valueOf(), { language: 'no', largest: 3 })
      } */
      return lastTs
    }
  },
  getters: {
    getSiteTotal: state => { return state.siteTotal },
    getFromDate: state => { return state.fromDate },
    getToDate: state => { return state.toDate }
  }
}
export default MySitesStore
