/* global
ioSocket
*/
import { Module, ActionContext } from 'vuex'
import { RootState } from '../../typedef'
import {
  MeterDataQuery,
  EditMeterStoreState,
  MeterData,
  EosTag,
  MeterPoint,
  EosTagValue
} from './typedef'

const namespaced = true

const EditMeterStore: Module<EditMeterStoreState, RootState> = {
  namespaced,
  state: {
    singularValue: []
  },
  mutations: {
    addSingularValue (state, meterData: MeterData[]) {
      state.singularValue.push(...meterData)
    },
    clearSingularValue (state) {
      state.singularValue = []
    }
  },
  actions: {
    async fetchMeterData (
      action: ActionContext<EditMeterStoreState, RootState>,
      meterDataQuery: MeterDataQuery
    ): Promise<MeterData[]> {
      action.commit('clearSingularValue')

      if (!action.state.singularValue.length) {
        const relations: MeterData[] = []
        const relationPage = await ioSocket.get(
          '/EosData/get',
          {
            from: meterDataQuery.from,
            to: meterDataQuery.to,
            meters: [meterDataQuery.uuid],
            resolution: 'raw'
          }
        ) as MeterData[]
        relations.push(...relationPage)
        action.commit('addSingularValue', relations)
      }
      console.log('fetch raw data')
      return action.state.singularValue
    },
    async removeMeterData (
      action: ActionContext<EditMeterStoreState, RootState>,
      eosTag: EosTag
    ): Promise<object> {
      let res: object
      try {
        res = await ioSocket.get(
          '/EosData/remove',
          {
            uuid: eosTag.uuid,
            ts: eosTag.ts,
            resolution: 'raw'
          }
        ) as object
      } catch (error) {
        throw new Error(`Could not remove : ${eosTag}
        Error: ${error}`)
      }
      console.log('store deleted:', res)
      // action.commit('removeSingularValue', res)
      return res
    },
    async setMeterData (
      action: ActionContext<EditMeterStoreState, RootState>,
      inputData: EosTagValue
    ): Promise<object> {
      let res: object
      try {
        res = await ioSocket.get(
          '/EosData/setHour',
          {
            inputData
          }
        ) as object
      } catch (error) {
        throw new Error(`Could not set : ${inputData}
        Error: ${error}`)
      }
      // console.log('store setMeterData:', res)
      return res
    },
    async destroyAggregated (
      action: ActionContext<EditMeterStoreState, RootState>,
      meterPoint: MeterPoint
    ): Promise<object[]> {
      let res: object[]
      try {
        res = await ioSocket.post(
          '/EosData/destroyAggregatedValues',
          {
            uuids: meterPoint.uuids,
            ts: meterPoint.ts,
            resolution: meterPoint.resolution
          }
        ) as object[]
      } catch (error) {
        throw new Error(`Could not update : ${meterPoint}
        Error: ${error}`)
      }
      console.log('destroyAggregated', res)
      return res
    }
  }
}
export default EditMeterStore
