/* global
ioSocket
*/
import _, { isArray } from 'lodash'
import { Module, ActionContext } from 'vuex'
import { RootState } from '../../typedef'
import {
  ReportEdits,
  ReportFull,
  ReportListItem,
  ReportNode,
  ReportStoreState
} from './typedef'

const namespaced = true

export const ReportStore: Module<ReportStoreState, RootState> = {
  namespaced,
  state: {
    reportList: [],
    selectedReportFull: undefined,
    selectedReportUuid: '',
    selectedGraphNodes: [],
    editMode: true,
    createReportMode: false,
    hasChanged: false,
    nodeMap: {}
  },
  mutations: {
    updateReportList (state, reports) {
      state.reportList = _.orderBy(reports, 'updatedAt', ['desc'])
    },
    updateNode (state, node) {
      state.nodeMap[node.uuid] = node
    },
    addNode (state, node) {
      if (node.type === 'graph') {
        state.selectedGraphNodes = [...state.selectedGraphNodes, node]
        if (state.selectedReportFull) {
          const summaryNode = _.find(state.selectedReportFull.nodes,
            { type: 'energySummary' })
          state.selectedReportFull.nodes = summaryNode
            ? [summaryNode, ...state.selectedGraphNodes] : []
        }
      }
    },
    deleteNode (state, nodeUuid) {
      state.selectedGraphNodes = _.filter(state.selectedGraphNodes, n => {
        return n.uuid !== nodeUuid
      })
      if (state.selectedReportFull) {
        state.selectedReportFull.nodes =
          _.filter(state.selectedReportFull.nodes, n => {
            return n.uuid !== nodeUuid
          })
      }
    },
    updateReport (state, report: ReportFull) {
      // Order files from newest to oldest
      state.selectedReportFull = {
        ...report,
        files: report.files
          ? _.orderBy(report.files, 'timestamp', ['desc'])
          : []
      }
    },
    updateLocalNode (state, node) {
      state.hasChanged = true
      state.nodeMap[node.uuid] = _.cloneDeep(node)
    },
    updateNodesFromReport (state, report) {
      state.selectedGraphNodes = state.selectedReportFull
        ? _.filter(state.selectedReportFull.nodes,
          { type: 'graph' })
        : []

      _.forEach(report.nodes, n => {
        state.nodeMap[n.uuid] = n
      })
    },
    updateSelectedReport (state, reportUuid: string) {
      state.selectedReportUuid = reportUuid
    },
    updateEditMode (state, editMode: boolean) {
      state.editMode = editMode
    },
    updateCreateReportMode (state, createReportMode: boolean) {
      state.createReportMode = createReportMode
    },
    updateHasChanged (state, hasChanged: boolean) {
      state.hasChanged = hasChanged
    }
  },
  actions: {
    async fetchReportList (
      action: ActionContext<ReportStoreState, RootState>,
      siteUuid: string
    ): Promise<ReportListItem[]> {
      await ioSocket.get('/Report/getList', {
        sites: siteUuid
      }).then(list => {
        // console.log('fetch report list', list)
        action.commit('updateReportList', (list as {reports: []}[])[0].reports)
      })
      return action.state.reportList
    },
    async fetchReport (
      action: ActionContext<ReportStoreState, RootState>,
      reportUuid: string
    ): Promise<ReportFull|null> {
      if (reportUuid.length !== 36) return null
      const report = await ioSocket.get('/Report/get', {
        reports: reportUuid
      }).then(report => {
        // console.log('fetch report full', report)
        if (isArray(report) && report.length > 0) {
          action.commit('updateReport', report[0])
          action.commit('updateNodesFromReport', report[0])
          return report[0]
        }
      })
      return report
    },
    async editReport (
      action: ActionContext<ReportStoreState, RootState>,
      edits: ReportEdits
    ): Promise<ReportFull | null> {
      const returned = await ioSocket.get('/Report/editReport', {
        reports: edits
      }).then(report => {
        return report
      }) as ReportFull[]
      if (isArray(returned) && returned.length > 0) {
        return returned[0]
      }
      return null
    },
    async createReport (
      action: ActionContext<ReportStoreState, RootState>,
      report: ReportFull
    ): Promise<ReportFull | null> {
      const nodes = _.map(action.state.selectedReportFull?.nodes, n => {
        return action.state.nodeMap[n.uuid]
      })
      console.log('createReport trest', report, nodes)
      // return null
      const returned = await ioSocket.get('/Report/create', {
        reports: {
          siteUuid: report.siteUuid,
          frequency: report.frequency,
          title: report.title,
          nodes: nodes
        }
      }).then(report => {
        console.log('createReport then', report)
        if (isArray(report) && report.length > 0) {
          action.commit('updateCreateReportMode', false)
          action.commit('updateSelectedReport', report[0].uuid)
          return report[0]
        }
        return report
      }) as ReportFull
      return returned
    },
    async deleteReport (
      action: ActionContext<ReportStoreState, RootState>,
      reportUuid: string
    ): Promise<void> {
      const result = await ioSocket.post('/Report/deleteReport',
        { reports: reportUuid }
      )
      console.log('deletereport result', result)
    },
    async createNode (
      action: ActionContext<ReportStoreState, RootState>,
      node: Partial<ReportNode>
    ): Promise<ReportNode> {
      console.log('createNode node', node)
      const res = await ioSocket.post('/Report/createNode', {
        nodes: node
      }).then(resNode => {
        // console.log('createNode then store', resNode)
        if (isArray(resNode) && resNode.length > 0) {
          // action.dispatch('fetchReport', node.reportUuid)
          action.commit('addNode', resNode[0])
          return resNode[0]
        }
      })
      return res
    },
    async editNode (
      action: ActionContext<ReportStoreState, RootState>,
      node: Partial<ReportNode>
    ): Promise<boolean> {
      let res = false
      await ioSocket.post('/Report/editNode', {
        nodes: node
      }).then(resNode => {
        if (isArray(resNode) && resNode.length > 0 && node.type === 'graph') {
          action.commit('deleteNode', node.uuid)
          action.commit('addNode', resNode[0])
        }
        res = true
      })
      return res
    },
    async deleteNode (
      action: ActionContext<ReportStoreState, RootState>,
      nodeUuid: string
    ): Promise<boolean> {
      let res = false
      await ioSocket.post('/Report/deleteNode', {
        nodes: nodeUuid
      }).then(resNode => {
        console.log('deleteNode then', resNode)
        action.commit('deleteNode', nodeUuid)
        res = true
      })
      return res
    },
    async setSubscription (
      action: ActionContext<ReportStoreState, RootState>,
      report: ReportListItem
    ): Promise<boolean> {
      const subed = report.subscribed
      const newState = await ioSocket.get('/Report/setSubscription', {
        reports: {
          uuid: report.uuid,
          action: subed ? 'unsub' : 'sub'
        }
      }).then(() => {
        action.commit('updateReportList', _.map(action.state.reportList, r => {
          return r.uuid === report.uuid
            ? { ...r, subscribed: !r.subscribed }
            : r
        }))
        if (action.state.selectedReportFull?.uuid === report.uuid) {
          action.commit('updateReport', {
            ...action.state.selectedReportFull, subscribed: !subed
          })
        }
        return !subed
      }).catch(e => {
        console.warn(e)
        return subed
      })
      return newState
    },
    async generateReport (
      action: ActionContext<ReportStoreState, RootState>,
      report: ReportFull
    ): Promise<void> {
      return ioSocket.post('/Report/generateReport', { report: report })
        .then(function (response) {
          const url = window.URL.createObjectURL(
            new Blob(
              [response as Blob],
              { type: 'application/octet-stream' }
            )
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Rapport.pdf')
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch(function (err) {
          console.error('_previewReport', err)
          return err
        })
    },
    async downloadReport (
      action: ActionContext<ReportStoreState, RootState>,
      fileUuid: string
    ): Promise<void> {
      return ioSocket.get(
        '/Report/getReportFile',
        { file: fileUuid }
      )
        .then(function (file) {
          const blob = new Blob([file as Blob], { type: 'application/pdf' })
          const elem = window.document.createElement('a')
          elem.href = window.URL.createObjectURL(blob)
          const fileName = action.state.selectedReportFull
            ? action.state.selectedReportFull.title : 'Rapport'
          elem.download = fileName + '.pdf'
          document.body.appendChild(elem)
          elem.click()
          document.body.removeChild(elem)
        })
        .catch(function (err) {
          console.error('savePdf', err)
        })
    }
  },
  getters: {
    getReportList: state => { return state.reportList },
    getSelectedReportUuid: state => { return state.selectedReportUuid },
    getSelectedReport: state => {
      return state.selectedReportUuid
        ? state.selectedReportFull
        : {}
    },
    getEditMode: state => { return state.editMode },
    getHasChanged: state => { return state.hasChanged },
    getCreateReportMode: state => { return state.createReportMode },
    getSelectedSummaryNode: state => {
      return state.selectedReportFull
        ? _.find(state.selectedReportFull.nodes,
          { type: 'energySummary' })
        : {}
    },
    getSelectedGraphNodes: state => {
      return state.selectedGraphNodes
    },
    getNodeMap: state => { return state.nodeMap }
  }
}
export default ReportStore
