/* global
ioSocket
*/
import { Module, ActionContext } from 'vuex'

import { RootState } from '../../typedef'

export interface UserState {
  user: User;
}

export type Role = 'registered' | 'admin'

// also contains some other stuff, but not needed (yet?)
export interface User {
  username: string;
  roles: Role[];
  // isAdmin: boolean
  lang: string;
  displayName: string;
  email: string;
  favoriteSensors?: {uuid: string; siteUuid: string}[];
}

const state: UserState = {
  user: {
    username: '',
    displayName: '',
    roles: [],
    lang: 'nb',
    email: 'a@a.no'
  }
}

const namespaced = true

export const UserStore: Module<UserState, RootState> = {
  namespaced,
  state,
  mutations: {
    setUser (state, user: User) {
      state.user = user
    }
  },
  // getters,
  actions: {
    /**
     * returns user, but should probably subscribe to
     * state instead of using return value.
     * @param action
     */
    async fetchUser (
      action: ActionContext<UserState, RootState>
    ) {
      const user = (await ioSocket.get(
        '/User/getSelf'
      )) as User

      action.commit('setUser', user)

      // return user
    }
  }
}
