import _ from 'lodash'
import { TreeNode } from '../eos-plot/typedef'

export class MeterSelectorHelper {
  static filterSelected (
    nodes: TreeNode[],
    selected: (string | undefined)[]
  ): TreeNode[] {
    function checkTypeFit (node: TreeNode) {
      if (node.children && node.children.length > 0) {
        node.children = _.filter(node.children, node => checkTypeFit(node))
      }
      node.state.checked = selected.indexOf(node.data.meter.uuid) > -1
      node.state.visible = true
      return node.state.visible
    }
    return _.filter(_.cloneDeep(nodes), node => checkTypeFit(node))
  }
}
