/* global
ioSocket
*/
import { Resolution } from '@/store/modules/eos_plot_store/typedef'
import _ from 'lodash'
import { DateTime } from 'luxon'

export interface ApiEosDataMeter {
  uuid: string;
  values: Array<ApiEosDataPoint>;
}
export interface ApiEosDataPoint {
  value: number;
  ts: string;
  temperature: number;
}

export type EosData = { [uuid: string]: Array<ApiEosDataPoint> }

export default class EosDataQuery {
  meters: string[];
  from: string;
  to: string;
  resolution: Resolution;
  data: EosData | null;

  constructor (
    meters: Array<string>,
    from: string,
    to: string,
    resolution: Resolution
  ) {
    this.meters = meters
    this.from = from
    this.to = to
    this.resolution = resolution

    this.data = null
  }

  async get (force: boolean, evenGaps = true) {
    if (!this.data || force) {
      try {
        // to get the same result as the old version
        if (this.resolution === Resolution.hours) {
          this.from = DateTime.fromISO(this.from).toUTC()
            .plus({ hours: 1 }).toString()
          this.to = DateTime.fromISO(this.to).toUTC()
            .plus({ hours: 24 }).toString()
        } else {
          // This format gets temperature for the first row
          this.from = DateTime.fromISO(this.from).toUTC()
            .toString()
        }
        const rawData = await ioSocket
          .get('/EosData/getTemp', {
            meters: this.meters,
            resolution: this.resolution,
            from: this.from,
            to: this.to
          })
        // console.log('get eosdataquery')// , rawData)

        return this._parseRawData(rawData as ApiEosDataMeter[], evenGaps)
      } catch (error) {
        console.error('MeterDataQuery.get', error)
        throw error
      }
    } else { return Promise.resolve(this.data) }
  }

  _parseRawData (rawData: Array<ApiEosDataMeter>, evenGaps = true) {
    if (!this.data) { this.data = {} }

    _.each(rawData, (meter: ApiEosDataMeter) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.data![meter.uuid] = meter.values
    })
    if (this.resolution === 'hours' && evenGaps) this._evenGaps()

    return this.data
  }

  /**
   * Slow. Copied from old version
   */
  _evenGaps () {
    _.each(this.data, meterData => {
      let previousPoint = _.last(meterData)
      let currentPoint = null
      let currentPointTs = null
      let i = meterData.length - 2 // dont want last point

      let hourDifference = 0
      let gapValues = []
      let tempMoment: DateTime
      // iterates backwards!
      while (i > 0) {
        currentPoint = meterData[i]
        currentPointTs = DateTime.fromISO(currentPoint.ts)
        if (previousPoint === undefined) return

        hourDifference = DateTime.fromISO(previousPoint.ts).diff(
          currentPointTs, 'hours').hours
        // gap
        if (hourDifference > 1) {
          tempMoment = currentPointTs

          gapValues = _.times(hourDifference, () => {
            if (previousPoint === undefined) return undefined

            tempMoment = tempMoment.plus({ hours: 1 })
            return {
              ts: tempMoment.toUTC().toString(),
              value: previousPoint.value / hourDifference,
              temperature: previousPoint.temperature
            } as ApiEosDataPoint
          })

          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          meterData.splice(i + 1, 1, ...gapValues)
        }

        previousPoint = currentPoint
        i--
      }
    })
  }
}
