import _ from 'lodash'
import { DateTime } from 'luxon'
import { DataColumn } from '../eos-data-table/typedef'
import { MeterRow } from '../table-math/typedef'

export class ExportToCsv {
  /**
   * Saves a string as .csv file
   * @param text content
   * @param filenamePrefix [prefix]-[ddLLyyHHmm].csv
   */
  static export (text: string, filenamePrefix = 'EOS') {
    // same nameing as old version
    const filename = filenamePrefix + '-' +
      DateTime.local().toFormat('ddLLyyHHmm') + '.csv'

    const blob = new Blob(['\ufeff', text],
      { type: 'text/csv;charset=utf-8' })

    const elem = window.document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = filename
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }

  /**
   * For data from TableMath
   * @param raw Rows from a TableMath component
   * @param headers Heders from TableMath component
   * @param siteName Site name
   * @returns data as semicolon-separated text
   */
  static formatExportTMData (
    raw: MeterRow[],
    headers: any[],
    siteName: string
  ): string {
    const sortedRaw = _.orderBy(_.filter(_.map(raw, r => {
      // Meter is selected and should be included
      if (r.percentage !== '') return r
    }), Boolean), ['value'], ['desc'])
    // console.log('sortedRaw', sortedRaw)

    const columns: string[][] = []
    columns.push(['Anlegg', ..._.times(sortedRaw.length, () => {
      return siteName
    })])

    _.map(headers, v => {
      columns.push([v.text, ..._.map(sortedRaw, v.value)])
    })

    const transp = _.zip(...columns)

    const csvDataRows = _.map(transp, row => {
      return _.join(row, ';')
    })

    return _.join(csvDataRows, '\n')
  }

  /**
   * For data from EosDataTable
   * @param raw Rows from EosDataTable
   * @param getMeterName Function for getting meter name
   * @returns data as semicolon-separated text
   */
  static formatExportData (
    raw: DataColumn[],
    getMeterName: (uuid: string, includeSite: boolean) => string
  ): string {
    const sortedRaw = _.orderBy(raw, ['ts'], ['asc'])
    const rowsOfColumns: { [column: string]: Array<string> } = {}
    const columns: string[][] = []

    _.map(sortedRaw, (r) => {
      _.map(r, (v, i) => {
        if (!rowsOfColumns[i]) rowsOfColumns[i] = []
        rowsOfColumns[i].push(v)
      })
    })

    _.map(rowsOfColumns, (v, i) => {
      let columnName = i
      let columnIndex = 0
      if (i === 'ts') {
        columnName = 'Dato'
        columnIndex = 0
      } else if (i === 'temperature') {
        columnName = 'Temperatur [°C]'
        columnIndex = columns.length
      } else {
        columnName = getMeterName(i, false)
        // Right of Date, left of Temperature
        columnIndex = columns.length > 1 ? columns.length - 1 : 1
      }
      columns.splice(columnIndex, 0, ([columnName, ...v]))
    })

    const transp = _.zip(...columns)

    const csvDataRows = _.map(transp, row => {
      return _.join(row, ';')
    })

    return _.join(csvDataRows, '\n')
  }
}
